import Dropdown from '@stimulus-components/dropdown'

export default class extends Dropdown {
  static targets = ['menu']

  toggle(event) {
    const dispatched = this.dispatchToggle()
    if (dispatched.defaultPrevented) {
      return
    }
    super.toggle(event)
  }

  show(event) {
    if (!this.menuTarget.classList.contains('hidden')) {
      return
    }
    const dispatched = this.dispatch('opening')
    if (dispatched.defaultPrevented) {
      return
    }

    queueMicrotask(() => {
      this.enter()
    })
  }

  // override so we can dispatch the closing event, allow if event is dialog:closed
  hide(event) {
    if (this.menuTarget.classList.contains('hidden')) {
      return
    }

    if (
      !this.element.contains(event.target) ||
      event.type === 'dialog:closed'
    ) {
      if (this.eventTargetWithinDialog(event)) {
        return
      }

      const dispatched = this.dispatch('closing')
      if (dispatched.defaultPrevented) {
        return
      }
      this.leave()
    }
  }

  dispatchToggle() {
    if (this.transitioned) {
      return this.dispatch('closing')
    } else {
      return this.dispatch('opening')
    }
  }

  dispatchTransitionEnd(event) {
    if (this.hasMenuTarget && event.target !== this.menuTarget) return

    if (this.transitioned) {
      return this.dispatch('opened')
    } else {
      return this.dispatch('closed')
    }
  }

  eventTargetWithinDialog(event) {
    const closestDialog = event.target.closest('dialog[open]')
    return closestDialog !== null
  }
}
