import { Controller } from '@hotwired/stimulus'
import { post } from '@rails/request.js'
import { loadStripe } from '@stripe/stripe-js'

export default class extends Controller {
  static targets = [
    'confirmationToken',
    'expressCheckout',
    'expressCheckoutWrapper',
    'elements',
    'address',
    'customerName',
    'flash',
    'form',
    'orderId',
    'priceId',
    'setupIntentId',
    'submit',
    'waitingIndicator'
  ]
  static values = {
    amount: Number,
    currency: String,
    shippingAmount: Number,
    publishableKey: String,
    purchaseUrl: String,
    brandColor: String,
    offerName: String,
    isSubscription: Boolean
  }

  #paymentElements
  #stripe

  async connect() {
    this.#stripe = await loadStripe(this.publishableKeyValue)
    this.initializeElement()
    this.initializeExpressCheckout()
  }

  initializeExpressCheckout() {
    const expressElements = this.#stripe.elements({
      // This is usually `setup` but that does not allow the amount to be shown on the wallet display
      // This is undocumented and may change in the future
      mode: "subscription",
      amount: this.amountValue + this.shippingAmountValue,
      currency: this.currencyValue,
      paymentMethodCreation: 'manual',
      setupFutureUsage: 'off_session'
    })
    const expressCheckoutElement = expressElements.create('expressCheckout', {
      paymentMethods: {
        amazonPay: 'auto',
        applePay: 'auto',
        googlePay: 'auto',
        paypal: 'auto',
        link: 'auto'
      },
      paymentMethodOrder: ['googlePay', 'applePay', 'amazonPay', 'paypal']
    })

    expressCheckoutElement.on('click', (event) => {
      const params = {
        emailRequired: true
        // shippingAddressRequired: this.hasAddressTarget
      }

      // TODO: Add shippingRate flat rate to a target
      // if (this.hasAddressTarget) {
      //   params.shippingRates = [
      //     {
      //       id: 'free-real',
      //       displayName: `Free`,
      //       amount: 0
      //     },
      //     {
      //       id: 'free',
      //       displayName: `${
      //         this.shippingAmountValue > 0 ? 'Standard ' : 'Free '
      //       }Shipping`,
      //       amount: this.shippingAmountValue || 0
      //     }
      //   ]
      // }

      event.resolve(params)
    })

    expressCheckoutElement.on('ready', (event) => {
      // Only show element if there is an availablePaymentMethod
      if (
        event.availablePaymentMethods &&
        Object.values(event.availablePaymentMethods).some((method) => method)
      ) {
        this.expressCheckoutWrapperTarget.classList.remove('hidden')
      }
    })

    expressCheckoutElement.on('confirm', async (event) => {
      if (event.paymentMethodFailed) {
        event.paymentMethodFailed((status) => this.handleError(status))
        return
      }

      // TODO: What has higher precidence? Should add shipping_name to order?
      const address =
        event.shippingDetails?.address || event.billingDetails?.address
      const name = event.shippingDetails?.name || event.billingDetails?.name
      const params = { name, address, email: event.billingDetails?.email }

      await this.#setConfirmationToken(expressElements)
      await this.#submit(params)
    })

    expressCheckoutElement.mount(this.expressCheckoutTarget)
  }

  initializeElement() {
    // TODO: This should be passed in for re-use
    const appearance = {
      variables: {
        colorPrimary: '#0B051D',
        colorBackground: '#ffffff',
        colorText: '#30313d',
        colorDanger: '#df1b41'
      },
      rules: {
        '.Tab': {
          border: '1px solid #E0E6EB',
          boxShadow:
            '0px 1px 1px rgba(0, 0, 0, 0.03), 0px 3px 6px rgba(18, 42, 66, 0.02)'
        },
        '.AccordionItem--selected': {
          border: `1px solid var(--content-primary, ${this.brandColorValue})`,
          boxShadow:
            '0px 1px 1px rgba(0, 0, 0, 0.03), 0px 3px 6px rgba(18, 42, 66, 0.02)',
          backgroundColor: 'var(--content-background-base, #F8F5F4)'
        }
      }
    }

    // TODO: This should be passed in for re-use
    const options = {
      appearance,
      mode: 'setup',
      currency: this.currencyValue,
      paymentMethodCreation: 'manual',
      setupFutureUsage: 'off_session'
    }

    this.#paymentElements = this.#stripe.elements(options)

    // Those are already available in expressCheckout
    const paymentElement = this.#paymentElements.create('payment', {
      layout: {
        type: 'accordion',
        defaultCollapsed: false,
        radios: true,
        spacedAccordionItems: false
      },
      wallets: {
        applePay: 'never',
        googlePay: 'never'
      }
    })

    paymentElement.mount(this.elementsTarget)

    if (this.hasAddressTarget) {
      const addressElement = this.#paymentElements.create('address', {
        mode: 'shipping'
      })
      addressElement.mount(this.addressTarget)
      this.customerNameTarget.classList.add('hidden')
    }
  }

  async submitPayment(event) {
    event.preventDefault()

    const params = await this.#getPaymentParams()

    await this.#setConfirmationToken(this.#paymentElements)
    await this.#submit(params)
  }

  handleError(error) {
    if (typeof error === 'string') {
      this.flashTarget.classList.toggle('hidden', !error)
      this.flashTarget.textContent = error
    } else {
      console.log('Received error', error)
    }

    const elementsWrapper = this.elementsTarget
    elementsWrapper.scrollIntoView({ behavior: 'smooth', block: 'center' })

    this.setLoading(false)
  }

  setLoading(isLoading) {
    this.submitTargets.forEach((element) => {
      element.disabled = isLoading
    })
    this.waitingIndicatorTargets.forEach((element) => {
      element.classList.toggle('hidden', !isLoading)
    })
  }

  // Internal

  async #getPaymentParams() {
    const addressElement = this.#paymentElements.getElement('address')

    if (addressElement) {
      // fill in the customer name if address is complete
      const { complete, value: params } = await addressElement.getValue()

      if (complete) {
        return params
      }
    }

    return {}
  }

  async #handleNextAction(result) {
    const clientSecret = result.next_action.client_secret
    if (clientSecret) {
      const { order, price } = result
      if (order) this.orderIdTarget.value = order.id
      if (price) this.priceId.value = price.id

      const {
        setupIntent: { id: setupIntentId }
      } = await this.#stripe.handleNextAction({ clientSecret })
      this.confirmationTokenTarget.value = null
      this.setupIntentIdTarget.value = setupIntentId
      this.#submit()
    }
  }

  async #handleSubmit(params) {
    if (this.purchaseUrlValue) {
      const body = new FormData(this.formTarget)

      const options = { responseKind: 'json' }
      // TODO: Too rigid
      const { email, name, address } = params

      if (email) body.set('customer[email]', email)
      if (name) body.set('customer[name]', name)

      if (address) {
        const shippingAddressParams = new URLSearchParams(address)
        shippingAddressParams.forEach((value, key) =>
          body.append(`shipping_address[${key}]`, value)
        )
      }

      return await post(this.purchaseUrlValue, { body: body, ...options }).then(
        (response) => response.json
      )
    }

    return this.formTarget.submit()
  }

  async #setConfirmationToken(elements) {
    this.setLoading(true)

    try {
      const { error: submitError } = await elements.submit()
      if (submitError) {
        // There were errors with the values in the submission
        this.handleError(submitError)
        return
      }

      const { confirmationToken, error } =
        await this.#stripe.createConfirmationToken({ elements })

      if (error) {
        this.handleError(error)
        return
      }

      this.confirmationTokenTarget.value = confirmationToken.id

      return confirmationToken
    } catch (error) {
      this.handleError(error.message)
      this.setLoading(false)
    }
  }

  async #submit(params) {
    const result = await this.#handleSubmit(params)

    if (result.success) {
      // TODO:Not safe passing the customer id like this but currently it needs to be in a signed cookie,
      // so we let the server handle it (redirects twice)
      window.location.href = result.next_step
    } else {
      if (result.error) {
        const { message: error } = result.error
        this.handleError(error)
        this.setLoading(false)
      }

      result.next_action && this.#handleNextAction(result)
    }
  }
}
