import { Controller } from '@hotwired/stimulus'

// Connects to data-controller="dialog"
export default class extends Controller {
  connect() {
    this.open()
    // needed because ESC key does not trigger close event
    this.element.addEventListener('close', this.enableBodyScroll.bind(this))
    // Add event listener for ESC key
    this.element.addEventListener('cancel', this.handleCancel.bind(this))
  }

  disconnect() {
    this.element.removeEventListener('close', this.enableBodyScroll.bind(this))
    this.element.removeEventListener('cancel', this.handleCancel.bind(this))
  }

  // hide modal on successful form submission
  // data-action="turbo:submit-end->turbo-dialog#submitEnd"
  submitEnd(e) {
    if (e.detail.success) {
      this.close()
    }
  }

  open() {
    this.element.showModal()
    document.body.classList.add('overflow-hidden')
  }

  close(event) {
    this.element.classList.add('closing')
    this.enableBodyScroll()
  }

  enableBodyScroll() {
    document.body.classList.remove('overflow-hidden')
  }

  handleAnimationEnd(event) {
    if (
      event.animationName === 'slideDown' ||
      event.animationName === 'disappear'
    ) {
      this.element.close()
      this.element.classList.remove('closing')
      const frame = document.getElementById('turbo_dialog')
      frame.removeAttribute('src')
      frame.innerHTML = ''
      document.body.blur()
      this.dispatch('closed')
    }
  }

  // Add handler for ESC key
  handleCancel(event) {
    event.preventDefault() // Prevent default ESC behavior
    this.close()
  }
}
