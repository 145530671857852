import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static values = {
    clickOnConnect: { type: Boolean, default: false }
  }

  connect() {
    if (this.clickOnConnectValue) {
      this.element.click()
    }
  }

  click(event) {
    const formElements = ['INPUT', 'TEXTAREA', 'SELECT']
    if (formElements.includes(event.target.tagName)) {
      return
    }
    this.element.click()
  }
}
