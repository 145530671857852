import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['input']

  connect() {
    const placeholders = [
      'Sell real estate courses',
      'Create digital products',
      'Build online communities',
      'Share your expertise'
    ]
    let currentIndex = 1
    const input = this.inputTarget

    setInterval(() => {
      // Fade out
      input.style.opacity = '0'

      setTimeout(() => {
        // Change text and trigger jump animation
        input.placeholder = placeholders[currentIndex]
        input.style.opacity = '1'

        // Remove and re-add animation class to trigger it again
        input.classList.remove('jump-animation')
        void input.offsetWidth // Force reflow
        input.classList.add('jump-animation')

        currentIndex = (currentIndex + 1) % placeholders.length
      }, 200)
    }, 3000)
  }
}
