import { Controller } from "@hotwired/stimulus"
import { loadConnectAndInitialize } from "@stripe/connect-js"
import { post } from "@rails/request.js"

export default class extends Controller {
  static targets = [
    "notificationBanner",
    "wrapper"
  ]
  static values = {
    accountSessionPath: String,
    fontSrc: String,
    publishableKey: String,
  }

  connect() {
    const appearance = {
      variables: {
        colorPrimary: "#342E26",
        borderRadius: "12px",
        buttonBorderRadius: "10px",
        buttonSecondaryColorBackground: "#264339", // --secondary-moola
        buttonSecondaryColorText: "#F3F0E8", // --primary-crisp
        formBorderRadius: "12px",
        badgeBorderRadius: "8px"
      }
    }

    const stripeConnectInstance = loadConnectAndInitialize({
      publishableKey: this.publishableKeyValue,
      fetchClientSecret: this.#fetchClientSecret.bind(this),
      appearance,
      fonts: [{cssSrc: this.fontSrcValue}]
    })

    this.#initializeElement(stripeConnectInstance)
  }

  // Internal

  #clearError() {
    this.#showTargets()
    this.#showWrapper()
  }

  #displayError(error) {
    this.#hideTargets()
    // Display the error nessage in the DOM
    this.notificationBannerTarget.textContent = error
    this.#hideWrapper()
  }

  #hideTargets() {
    this.notificationBannerTarget.classList.add("hidden")
  }

  #hideWrapper() {
    this.wrapperTarget.classList.add("hidden")
  }

  #initializeElement(stripeConnectInstance) {
    if (this.hasNotificationBannerTarget) {
      const notificationBanner = stripeConnectInstance.create("notification-banner")
      this.notificationBannerTarget.appendChild(notificationBanner)
    }
  }

  #showTargets() {
    this.notificationBannerTarget.classList.remove("hidden")
  }

  #showWrapper() {
    this.wrapperTarget.classList.remove("hidden")
  }

  async #fetchClientSecret() {
    const response = await post(this.accountSessionPathValue)

    if (!response.ok) {
      const { error } = await response.json
      this.#displayError(error)
      return
    } else {
      const { client_secret: clientSecret } = await response.json

      this.#clearError()
      return clientSecret
    }
  }
}
