import { DependableController } from '@bullet-train/fields'

export default class extends DependableController {
  updateFirstDependent(event) {
    if (!this.hasDependents) {
      return false
    }

    this.dependents[0].dispatchEvent(
      new CustomEvent(`${this.identifier}:updated`, {
        detail: { event: event },
        bubbles: true,
        cancelable: false
      })
    )
  }
}
