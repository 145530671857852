window.toastNotification = (message, icon_raw = null) => {
  let template = document.getElementById('toast-notification-template')
  if (!template) {
    return null
  }

  let content = template.content.firstElementChild.cloneNode(true)

  if (!content) {
    return null
  }

  content.innerHTML = content.innerHTML
    .replaceAll('{{ message }}', message)
    .replaceAll('{{ icon_raw }}', icon_raw || '')

  // comes with its own turbo_stream.apend targetting the right place in the DOM to append the toast
  document.body.insertAdjacentHTML('beforeend', content.outerHTML)
}
