import { Controller } from '@hotwired/stimulus'
import { Picker } from 'emoji-mart'
import { useDebounce } from 'stimulus-use'

export default class extends Controller {
  static values = {
    endpoint: String
  }
  static targets = [
    'input',
    'display',
    'emojiPicker',
    'iconPicker',
    'query',
    'frame'
  ]
  static debounces = ['submitQuery']

  connect() {
    useDebounce(this, { wait: 350 })

    this.emojiPicker = new Picker({
      data: async () => {
        const response = await fetch(
          'https://cdn.jsdelivr.net/npm/@emoji-mart/data'
        )

        return response.json()
      },
      theme: 'light',
      onEmojiSelect: (emoji, event) => {
        this.displayTarget.innerHTML = emoji.native
        this.inputTarget.value = emoji.native
        this.dispatchNativeEvent(event)
      }
    })

    this.emojiPickerTarget.appendChild(this.emojiPicker)
  }

  async submitQuery() {
    this.frameTarget.src = `${this.endpointValue}?query=${this.queryTarget.value}&frame_id=${this.frameTarget.id}`
  }

  selectIcon(event) {
    const target = event.currentTarget
    const value = target.dataset.value
    this.inputTarget.value = `noun_project:${value}`
    this.displayTarget.innerHTML = target.innerHTML
    this.dispatchNativeEvent(event)
  }

  dispatchNativeEvent(event) {
    const nativeEventName = 'input'

    if (event.defaultPrevented) {
      return
    }

    const nativeEvent = new CustomEvent(nativeEventName, {
      detail: { event: event },
      bubbles: true,
      cancelable: true
    })
    this.inputTarget.dispatchEvent(nativeEvent)

    if (nativeEvent.defaultPrevented) {
      event.preventDefault()
    }
  }

  disconnect() {
    if (this.emojiPicker === undefined) {
      return
    }
    this.emojiPicker.unregister()
  }
}
