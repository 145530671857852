import { Controller } from '@hotwired/stimulus'
import AutoNumeric from 'autonumeric'

export default class extends Controller {
  static targets = ['input', 'hiddenDeformattedInput']
  static values = {
    locale: String,
    currency: String,
    max: { type: Number, default: 999999.99 },
    min: { type: Number, default: 0 }
  }

  connect() {
    this.initFormatter()

    this.autoNumeric = new AutoNumeric(
      this.inputTarget,
      this.autoNumericOptions
    )

    this.initHiddenDeformattedInput()

    if (this.input.hasAttribute('autofocus')) {
      this.placeCursorBeforeDecimal()
    }
  }

  disconnect() {
    this.teardownFormatter()
  }

  placeCursorBeforeDecimal() {
    const { decimalSymbol } = this.autoNumericOptions

    const cursorPosition = this.input.value.indexOf(decimalSymbol)
    if (cursorPosition !== -1) {
      this.input.setSelectionRange(cursorPosition, cursorPosition)
    } else {
      this.input.focus()
    }
  }

  get inputHasFocus() {
    return this.input === document.activeElement
  }

  get input() {
    if (this.hasInputTarget) {
      return this.inputTarget
    }
    return this.element
  }

  initFormatter() {
    this.formatter = Intl.NumberFormat(this.localeValue, {
      currency: this.currencyValue,
      style: 'currency',
      currencyDisplay: 'narrowSymbol',
      minimumFractionDigits: 0
    })
  }

  get autoNumericOptions() {
    const parts = this.formatter.formatToParts(12345.67)

    const currencySymbol = parts.find((part) => part.type === 'currency')?.value
    const groupSymbol = parts.find((part) => part.type === 'group')?.value
    const decimalSymbol = parts.find((part) => part.type === 'decimal')?.value
    const currencySymbolPlacement = this.localeValue.includes('de') ? 's' : 'p'

    const { maximumFractionDigits } = this.formatter.resolvedOptions()

    return {
      decimalCharacter: decimalSymbol || '.',
      decimalPlaces: maximumFractionDigits,
      digitGroupSeparator: groupSymbol || ',',
      minimumValue: this.minValue,
      maximumValue: this.maxValue,
      unformatOnSubmit: false,
      unformatOnHover: false,
      currencySymbol: currencySymbol,
      currencySymbolPlacement: currencySymbolPlacement,
      modifyValueOnWheel: false
    }
  }

  teardownFormatter() {
    this.formatter = null
  }

  teardownAutoNumeric() {
    this.autoNumeric = null
  }

  initHiddenDeformattedInput() {
    if (this.hasHiddenDeformattedInputTarget) {
      return
    }

    const hiddenInput = document.createElement('input')
    hiddenInput.type = 'hidden'
    hiddenInput.name = this.input.name
    hiddenInput.setAttribute(
      `data-${this.identifier}-target`,
      'hiddenDeformattedInput'
    )
    this.element.appendChild(hiddenInput)
    this.syncHiddenField()
  }

  syncHiddenField() {
    let deformatted = AutoNumeric.unformat(this.input.value, {
      ...this.autoNumericOptions,
      rawValueDivisor: 0.01
    })

    this.hiddenDeformattedInputTarget.value = parseInt(deformatted, 10)
  }
}
