import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static values = { loaderThreshold: Number }

  connect() {
    this.element.addEventListener('submit', this.handleSubmit.bind(this))
  }

  save(event) {
    // If this is triggered by a destroy checkbox, don't save
    if (
      event?.target?.type === 'checkbox' &&
      event.target.name.includes('[_destroy]')
    ) {
      // Let the checkbox handle the UI update
      return
    }
    document.getElementById('offer-wizard-save-button').click()
  }

  handleSubmit(event) {
    const loaderThreshold = this.loaderThresholdValue

    const buttons = document.querySelectorAll(
      `[form=${this.element.id}].allow-loader`
    )

    this.timeout = setTimeout(() => {
      buttons.forEach((button) => {
        button.classList.add('button-loader')
      })
    }, loaderThreshold)

    event.target.addEventListener('ajax:complete', () => {
      buttons.forEach((button) => {
        button.classList.remove('button-loader')
      })
      clearTimeout(this.timeout)
    })
  }
}
