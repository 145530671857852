import { Controller } from '@hotwired/stimulus'

// <meta name="intercom-settings" data-controller="intercom" data-intercom-alignment-value="left">
// <meta name="intercom-settings" data-controller="intercom" data-intercom-hide-intercom-value="true">
export default class extends Controller {
  static values = {
    hideIntercom: { type: Boolean, default: false },
    alignment: { type: String, default: 'right' }
  }

  connect() {
    if (this.hideIntercomValue) {
      this.hideIntercom()
    }
    if (this.alignmentValue) {
      this.updateAlignment()
    }
  }

  hideIntercom() {
    if (window.Intercom) {
      Intercom('hide')
      Intercom('update', { hide_default_launcher: true })
    }
  }

  updateAlignment() {
    if (window.Intercom) {
      Intercom('update', { alignment: this.alignmentValue })
    }
  }
}
