import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['openButton']
  static values = {
    sessionKey: { type: String, default: 'mobile_notice_shown' },
    mobileBreakpoint: { type: Number, default: 640 }
  }

  connect() {
    if (this.hasBeenShownThisSession) {
      this.element.remove()
      return
    }

    this.initializeDialog()
    this.bindResizeHandler()
  }

  disconnect() {
    window.removeEventListener('resize', this.resizeHandler)
  }

  initializeDialog() {
    if (this.shouldShowDialog()) {
      // Small delay to ensure dialog opens smoothly
      setTimeout(() => {
        this.openButtonTarget.click()
      }, 50)
    } else {
      this.element.remove()
    }
  }

  handleClose(event) {
    // Prevent default if this is from ESC key
    if (event?.type === 'keydown') {
      event.preventDefault()
    }

    // Save to session storage before closing
    sessionStorage.setItem(this.sessionKeyValue, 'true')

    // Remove element after animation
    setTimeout(() => {
      this.element.remove()
    }, 300)
  }

  bindResizeHandler() {
    this.resizeHandler = () => {
      if (window.innerWidth >= this.mobileBreakpointValue) {
        this.openButtonTarget.click()
      }
    }
    window.addEventListener('resize', this.resizeHandler)
  }

  shouldShowDialog() {
    return window.innerWidth < this.mobileBreakpointValue
  }

  get hasBeenShownThisSession() {
    return sessionStorage.getItem(this.sessionKeyValue) === 'true'
  }
}
