import { Controller } from '@hotwired/stimulus'
import emojiRegex from 'emoji-regex'

/**
 * Prevents emoji input in regular text fields
 * @stimulus emoji-prevention
 */
export default class extends Controller {
  static targets = ['input', 'error']
  static values = {
    message: { type: String, default: 'Emojis are not allowed in this field' },
    debug: { type: Boolean, default: false }
  }
  static classes = ['visible']

  /**
   * Get the emoji regex pattern
   * @private
   */
  getEmojiRegex() {
    return emojiRegex()
  }

  /**
   * Prevents direct emoji input
   * Action: beforeinput->emoji-prevention#preventEmoji
   */
  preventEmoji(event) {
    if (this.getEmojiRegex().test(event.data)) {
      event.preventDefault()
      this.debouncedShowFeedback()
    }
  }

  /**
   * Handles paste event
   * Action: paste->emoji-prevention#handlePaste
   */
  handlePaste(event) {
    const text = event.clipboardData?.getData('text')
    if (!text || !this.getEmojiRegex().test(text)) return

    event.preventDefault()
    const cleanText = this.removeEmojis(text)
    this.updateInputValue(event.target, cleanText)
    this.debouncedShowFeedback()
  }

  /**
   * Removes emoji characters from text
   * @private
   */
  removeEmojis(text) {
    return text.replace(this.getEmojiRegex(), '')
  }

  /**
   * Updates input value maintaining cursor position
   * @private
   */
  updateInputValue(input, cleanText) {
    const start = input.selectionStart
    input.value =
      input.value.slice(0, start) +
      cleanText +
      input.value.slice(input.selectionEnd)
    input.selectionStart = input.selectionEnd = start + cleanText.length
  }

  /**
   * Shows feedback message with debounce
   * @private
   */
  debouncedShowFeedback = this.debounce(() => {
    this.showFeedback()
  }, 100)

  /**
   * Shows feedback message
   * @private
   */
  showFeedback() {
    if (!this.hasErrorTarget) return

    this.clearFeedbackTimeout()
    this.errorTarget.textContent = this.messageValue
    this.errorTarget.classList.add(this.visibleClass)

    this._feedbackTimeout = setTimeout(() => this.hideFeedback(), 3000)
  }

  /**
   * Hides feedback message
   * @private
   */
  hideFeedback() {
    if (!this.hasErrorTarget) return
    this.errorTarget.textContent = ''
    this.errorTarget.classList.remove(this.visibleClass)
  }

  /**
   * Clears the feedback timeout
   * @private
   */
  clearFeedbackTimeout() {
    if (this._feedbackTimeout) {
      clearTimeout(this._feedbackTimeout)
      this._feedbackTimeout = null
    }
  }

  /**
   * Debounce utility function
   * @private
   */
  debounce(func, wait) {
    let timeout
    return (...args) => {
      clearTimeout(timeout)
      timeout = setTimeout(() => func.apply(this, args), wait)
    }
  }
}
