import AutoSubmit from '@stimulus-components/auto-submit'

export default class extends AutoSubmit {
  submit() {
    const event = new CustomEvent('auto-submit', {
      cancellable: true,
      bubbles: true
    })
    this.element.dispatchEvent(event)

    if (!event.defaultPrevented) {
      super.submit()
    }
  }
}
