import { Controller } from '@hotwired/stimulus'

// enable submit button when input is changed
// <form data-controller="form-changes" data-action="change->form-changes#enableSubmit input->form-changes#enableSubmit">
// <input data-form-changes-target="input">
// <button data-form-changes-target="submit">Submit</button>
// </form>

// optionally require all inputs to be filled before submitting
export default class extends Controller {
  static targets = ['submit']

  connect() {
    this.submitTarget.disabled = true
  }

  enableSubmit() {
    this.submitTarget.disabled = false
  }
}
