import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="clipboard"
export default class extends Controller {
  static targets = ['button', 'source']
  static values = {
    successDuration: { type: Number, default: 2000 }
  }

  connect () {
    if (!this.hasButtonTarget) return

    this.originalText = this.buttonTarget.innerText
  }

  copy (event) {
    event.preventDefault()

    let value = (this.sourceTarget.innerText || this.sourceTarget.value).trim()
    this.copyToClipboard(value)
      .then(() => this.copied())
      .catch(() => console.log('error'));
  }

  copied () {
    if (!this.hasButtonTarget) return

    if (this.timeout) {
      clearTimeout(this.timeout)
    }
    const successContent = this.data.get('successContent')
    if (successContent) {
      this.buttonTarget.innerText = successContent
      this.timeout = setTimeout(() => {
        this.buttonTarget.innerText = this.originalText
      }, this.successDurationValue)
    }
  }

  copyToClipboard(value) {
    // navigator clipboard api needs a secure context (https)
    if (navigator.clipboard && window.isSecureContext) {
      return navigator.clipboard.writeText(value);
    } else {
      let textArea = document.createElement("textarea");
      textArea.value = value;
      textArea.style.position = "fixed";
      textArea.style.left = "-999999px";
      textArea.style.top = "-999999px";
      document.body.appendChild(textArea);
      textArea.focus();
      textArea.select();
      return new Promise((res, rej) => {
        document.execCommand('copy') ? res() : rej();
        textArea.remove();
      });
    }
  }
}
