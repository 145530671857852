import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['dropdown', 'form']

  connect() {
    this.stepId = this.element.dataset.stepId
    this.offerOrderBumpsPath = this.element.dataset.offerOrderBumpsPath
  }

  toggleDropdown() {
    this.dropdownTarget.classList.toggle('hidden')
  }

  hideDropdown() {
    this.dropdownTarget.classList.add('hidden')
  }
}
