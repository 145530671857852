
import { Controller } from "@hotwired/stimulus"
import ahoy from "ahoy.js"


export default class extends Controller {
  static values = {
    trackClicks: Boolean,
    trackSubmits: Boolean,
  }

  connect() {
    const data = { ...this.element.dataset }

    this.removeEventTrackingDataAttributes(data);

    ahoy.trackView(data);

    if (this.trackClicksValue) {
      this.trackClicks("a, button, input[type=submit]", data);
    }

    if (this.trackSubmitsValue) {
      this.trackSubmits("form", data);
    }
  }

  removeEventTrackingDataAttributes(data) {
    delete data.controller;
    Object.keys(data).forEach(key => {
      if (key.startsWith('eventTracking')) {
        delete data[key];
      }
    });
  }

  trackClicks = function (selector, additionalProperties = {}) {

    if (selector === undefined) {
      throw new Error("Missing selector");
    }
    onEvent("click", selector, function (e) {
      const properties = eventProperties.call(this, e);
      properties.text = properties.tag === "input" ? this.value : (this.textContent || this.innerText || this.innerHTML).replace(/[\s\r\n]+/g, " ").trim();
      properties.href = this.href;
      ahoy.track("$click", { ...properties, ...additionalProperties });
    });
  };

  trackSubmits = function (selector, additionalProperties = {}) {
    if (selector === undefined) {
      throw new Error("Missing selector");
    }
    onEvent("submit", selector, function (e) {
      const properties = eventProperties.call(this, e);
      ahoy.track("$submit", { ...properties, ...additionalProperties });
    });
  };

}



// Overrides from ahoy.js, since these aren't exported

function onEvent(eventName, selector, callback) {
  document.addEventListener(eventName, function (e) {
    const matchedElement = matchesSelector(e.target, selector);
    if (matchedElement) {
      const skip = getClosest(matchedElement, "data-ahoy-skip");
      if (skip !== null && skip !== "false") return;

      callback.call(matchedElement, e);
    }
  });
}

function matchesSelector(element, selector) {
  const matches = element.matches ||
    element.matchesSelector ||
    element.mozMatchesSelector ||
    element.msMatchesSelector ||
    element.oMatchesSelector ||
    element.webkitMatchesSelector;

  if (matches) {
    if (matches.apply(element, [selector])) {
      return element;
    } else if (element.parentElement) {
      return matchesSelector(element.parentElement, selector);
    }
    return null;
  } else {
    log("Unable to match");
    return null;
  }
}

function getClosest(element, attribute) {
  for (; element && element !== document; element = element.parentNode) {
    if (element.hasAttribute(attribute)) {
      return element.getAttribute(attribute);
    }
  }

  return null;
}

function eventProperties() {
  return cleanObject({
    tag: this.tagName.toLowerCase(),
    id: presence(this.id),
    "class": presence(this.className),
    page: page(),
    section: getClosest(this, "data-section")
  });
}

function cleanObject(obj) {
  for (const key in obj) {
    if (Object.prototype.hasOwnProperty.call(obj, key)) {
      if (obj[key] === null) {
        delete obj[key];
      }
    }
  }
  return obj;
}

function page() {
  return window.location.pathname;
}

function presence(str) {
  return (str && str.length > 0) ? str : null;
}