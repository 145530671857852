import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['content', 'toggle']

  connect() {
    this.toggleTargets.forEach((target) => {
      target.setAttribute('data-more-text', target.innerText)
      if (!this.isTruncated()) {
        return (target.style.display = 'none')
      } else {
        if (!target.getAttribute('data-action')) {
          target.addEventListener('click', this.toggle.bind(this))
        }
      }
    })

    this.open = false
  }

  disconnect() {
    this.toggleTargets.forEach((target) => {
      target.removeEventListener('click', this.toggle.bind(this))
    })
  }

  toggle(event) {
    this.open === false ? this.show(event) : this.hide(event)
  }

  show(event) {
    this.open = true

    const target = event.target
    if (target.getAttribute('data-less-text')) {
      target.innerText = target.getAttribute('data-less-text')
    }
    this.contentTarget.style.setProperty('--read-more-line-clamp', "'unset'")
    this.element.setAttribute('open', '')
  }

  hide(event) {
    this.open = false

    const target = event.target
    if (target.getAttribute('data-more-text')) {
      target.innerText = target.getAttribute('data-more-text')
    }
    this.contentTarget.style.removeProperty('--read-more-line-clamp')
    this.element.removeAttribute('open')
  }

  isTruncated() {
    const isTextClamped = (elm) => elm.scrollHeight > elm.clientHeight
    return isTextClamped(this.contentTarget)
  }
}
