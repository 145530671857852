import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["sliderField", "numberField"]

  static values = {
    percentageCssPropertyName: {
      type: String,
      default: "--slider-percentage" // used for styling the background of the slider
    }
  }

  connect() {
    this.setPercentageCssProperty()
  }

  updateOtherField(event) {
    if (!this.hasNumberFieldTarget) { return }
    if (!this.hasSliderFieldTarget) { return }

    const field = event.currentTarget
    const otherField = this.otherFieldFor(field)

    const roundedValue = this.roundValueFor(field)

    if (otherField) {
      otherField.value = roundedValue
    }

    this.setPercentageCssProperty()
  }

  roundValue(event) {
    const field = event.currentTarget
    field.value = this.roundValueFor(field)
  }

  roundValueFor(field) {
    if (!field) { return undefined }
    const step = field.step || 1
    return Math.round(field.value / step) * step
  }

  otherFieldFor(field) {
    if (field === this.numberFieldTarget) {
      return this.sliderFieldTarget
    } else if (field === this.sliderFieldTarget) {
      return this.numberFieldTarget
    }

    return undefined
  }

  setPercentageCssProperty() {
    if (!this.hasSliderFieldTarget) { return }
    if (!this.hasPercentageCssPropertyNameValue) { return }
    this.element.style.setProperty(this.percentageCssPropertyNameValue, `${this.sliderFieldTarget.value}%`)
  }
}
