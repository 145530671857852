import { Controller } from "@hotwired/stimulus"
import Cookies from "js-cookie"

export default class extends Controller {
  static targets = ["collapsable", "toggle"]

  static values = {
    preference: String, // Name of cookie to store preference
  }

  connect() {
    this.#initializePreference()
    this.#initializeToggleTarget()
  }

  renderPreference() {
    if (this.prefersPreference) {
      this.#showCollapsable()
    } else {
      this.#hideCollapsable()
    }
  }

  togglePreference() {
    if (this.prefersPreference) {
      this.#setPreference(false)
    } else {
      this.#setPreference(true)
    }
    this.renderPreference()
  }

  get prefersPreference() {
    return Cookies.get(this.preferenceValue) !== "false"
  }

  // Internal

  #hideCollapsable() {
    this.collapsableTargets.forEach((element) => {
      element.addEventListener("transitionend", () => {
        element.classList.add("hidden")
      }, { once: true })
      element.classList.add("opacity-0")
    })
  }

  #initializePreference() {
    this.renderPreference()
  }

  #initializeToggleTarget() {
    if (this.hasToggleTarget) {
      this.toggleTarget.addEventListener("click", (event) => {
        event.preventDefault()
        this.togglePreference()
      })
    }
  }

  #setPreference(value) {
    Cookies.set(this.preferenceValue, value.toString(), { expires: 365, path: "/" })
  }

  #showCollapsable() {
    this.collapsableTargets.forEach((element) => {
      element.classList.remove("opacity-0", "hidden")
      element.classList.add("opacity-100")
    })
  }
}
