import Carousel from '@stimulus-components/carousel'
import Swiper from 'swiper'
import 'swiper/css/bundle'

export default class extends Carousel {
  static targets = ['main', 'thumbnails', 'prevButton', 'nextButton']
  static values = {
    activeSlide: Number
  }

  connect() {
    this.initSwipers()
  }

  initSwipers() {
    if (!this.hasThumbnailsTarget) return

    this.thumbnailsSwiper = new Swiper(this.thumbnailsTarget, {
      slidesPerView: 'auto',
      spaceBetween: 6,
      slidesOffsetAfter: 30,
      slideToClickedSlide: true,
      watchSlidesProgress: true
    })

    this.mainSwiper = new Swiper(this.mainTarget, {
      slidesPerView: 1.25,
      spaceBetween: 16,
      breakpoints: {
        768: {
          slidesPerView: 1,
          spaceBetween: 0
        }
      },
      thumbs: {
        swiper: this.thumbnailsSwiper || null
      }
    })

    this.prevButtonTarget.disabled = true

    this.mainSwiper.on('slideChange', () => {
      this.activeSlideValue = this.mainSwiper.activeIndex

      // Disable prev button on first slide
      if (this.hasPrevButtonTarget) {
        this.prevButtonTarget.disabled = this.activeSlideValue === 0
      }

      // Disable next button on last slide
      if (this.hasNextButtonTarget) {
        this.nextButtonTarget.disabled =
          this.activeSlideValue === this.mainSwiper.slides.length - 1
      }
    })

    // The swiper instance.
    this.mainSwiper

    // Default options for every carousels.
    this.defaultOptions
  }

  teardownSwipers() {
    this.mainSwiper.destroy()
    this.thumbnailsSwiper.destroy()
  }

  nextSlide() {
    this.mainSwiper.slideNext()
    this.thumbnailsSwiper.slideNext()
  }

  prevSlide() {
    this.mainSwiper.slidePrev()
    this.thumbnailsSwiper.slidePrev()
  }

  disconnect() {
    this.teardownSwipers()
  }
}
