import Dialog from '@stimulus-components/dialog'

export default class extends Dialog {
  static classes = ['closingAnimation']

  static values = {
    preventResizeOnOpen: { type: Boolean, default: false },
    open: { type: Boolean, default: false }
  }

  connect() {
    if (this.openValue) {
      this.open()
    }
  }

  open(event) {
    this.animationDirection = 'opening'
    super.open(event)
    this.dispatch('opening')
  }

  close(event) {
    if (!this.dialogTarget.open) {
      return
    }
    this.animationDirection = 'closing'
    this.dispatch('closing')

    if (this.hasClosingAnimationClass) {
      this.dialogTarget.classList.add(...this.closingAnimationClasses)
    } else {
      super.close(event)
      this.dispatch('closed')
    }
  }

  handleAnimationEnd(event) {
    switch (this.animationDirection) {
      case 'opening':
        // focus handled at the start of the open method
        if (this.preventResizeOnOpenValue) {
          this.lockHeight()
        }
        break
      case 'closing':
        if (this.hasDialogTarget) {
          super.close()
          this.dispatch('closed')
          if (this.hasClosingAnimationClass && this.hasDialogTarget) {
            this.dialogTarget.classList.remove(...this.closingAnimationClasses)
          }
          this.unlockHeight()
        }
        break
    }
  }

  lockHeight() {
    if (!this.hasDialogTarget) {
      return
    }
    this.dialogTarget.style.height = `${this.dialogTarget.scrollHeight}px`
  }

  unlockHeight() {
    if (!this.hasDialogTarget) {
      return
    }
    this.dialogTarget.style.height = 'none'
  }
}
