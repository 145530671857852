import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static classes = ['fullAnimation', 'partialAnimation', 'endAnimation']
  static values = {
    inProgress: { type: Boolean, default: false }
  }

  connect() {
    this.usePartialAnimationIfInProgress()
  }

  markInProgress() {
    this.inProgressValue = true
  }

  markCompleted() {
    this.inProgressValue = false
  }

  usePartialAnimationIfInProgress() {
    if (!this.hasFullAnimationClass || !this.hasPartialAnimationClass) return
    if (!this.inProgressValue) return

    this.element.classList.remove(...this.fullAnimationClasses)
    this.element.classList.add(...this.partialAnimationClasses)
  }

  endAnimation() {
    if (!this.hasEndAnimationClass) return

    this.element.classList.remove(...this.fullAnimationClasses)
    this.element.classList.remove(...this.partialAnimationClasses)
    this.element.classList.add(...this.endAnimationClasses)

    this.element.style.animationPlayState = 'running' // end immediately
  }
}
