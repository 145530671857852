import { Controller } from '@hotwired/stimulus'
import { loadConnectAndInitialize } from '@stripe/connect-js'
import { post } from '@rails/request.js'

export default class extends Controller {
  static targets = [
    'error',
    'transactions',
    'financialAccount',
    'issuingCardsList',
    'issuingCard'
  ]
  static values = {
    publishableKey: String,
    accountSessionPath: String,
    ephemeralKeyPath: String,
    stripeFinancialAccountId: String,
    fontSrc: String
  }

  connect() {
    const stripeConnectInstance = loadConnectAndInitialize({
      publishableKey: this.publishableKeyValue,
      fetchClientSecret: this.fetchClientSecret.bind(this),
      // fonts: [
      //   {
      //     cssSrc: this.fontSrcValue
      //   }
      // ],
      appearance: {
        variables: {
          // fontFamily: 'Manrope', // fonts are the least of my concerns right now
          colorPrimary: '#342E26',
          borderRadius: '8px',
          buttonBorderRadius: '8px',
          formBorderRadius: '8px',
          badgeBorderRadius: '8px'
        }
      }
    })

    if (this.hasIssuingCardsListTarget) {
      const issuingCardsList =
        stripeConnectInstance.create('issuing-cards-list')

      issuingCardsList.setFetchEphemeralKey(this.fetchEphemeralKey.bind(this))
      issuingCardsList.setShowSpendControls(true)

      this.issuingCardsListTarget.appendChild(issuingCardsList)
    }

    if (this.hasIssuingCardTarget) {
      const issuingCard = stripeConnectInstance.create('issuing-card')

      issuingCard.setFetchEphemeralKey(this.fetchEphemeralKey.bind(this))
      issuingCard.setShowSpendControls(true)

      this.issuingCardTarget.appendChild(issuingCard)
    }

    if (this.hasTransactionsTarget) {
      const financialAccountTransactions = stripeConnectInstance.create(
        'financial-account-transactions'
      )

      financialAccountTransactions.setFinancialAccount(
        this.stripeFinancialAccountIdValue
      )
      this.transactionsTarget.appendChild(financialAccountTransactions)
    }

    if (this.hasFinancialAccountTarget) {
      const financialAccount = stripeConnectInstance.create('financial-account')
      financialAccount.setFinancialAccount(this.stripeFinancialAccountIdValue)
      this.financialAccountTarget.appendChild(financialAccount)
    }
  }

  async fetchClientSecret() {
    // Fetch the AccountSession client secret
    const response = await post(this.accountSessionPathValue)
    if (!response.ok) {
      // Handle errors on the client side here
      const { error } = await response.json
      console.error('An error occurred: ', error)
      this.paymentsTarget.classList.add('hidden')
      this.payoutsTarget.classList.add('hidden')
      this.transactionsTarget.classList.add('hidden')
      this.errorTarget.classList.remove('hidden')
      return undefined
    } else {
      const { client_secret: clientSecret } = await response.json
      this.errorTarget.classList.add('hidden')

      return clientSecret
    }
  }

  async fetchEphemeralKey(fetchParams) {
    const { issuingCard, nonce } = fetchParams
    const response = await post(this.ephemeralKeyPathValue, {
      body: {
        nonce,
        card_id: issuingCard
      }
    })
    if (!response.ok) {
      // Handle errors on the client side here
      const { error } = await response.json
      console.error('An error occurred: ', error)
      return undefined
    } else {
      return await response.json
    }
  }
}
