// Inspired by https://github.com/jumpstart-pro/jumpstart-pro-rails/blob/main/app/javascript/src/confirm.js

// Example usage:
//
//   <%= button_to "Delete", my_path, method: :delete, form: {
//     data: {
//       turbo_confirm: "Delete this tier?",
//       turbo_confirm_description: "You’ll lose all the prices you’ve added to Starter.",
//       turbo_confirm_button_text: "Yes, delete it",
//     }
//   } %>

function insertConfirmDialog(message, element, button) {
  let template = document.getElementById('confirm-dialog-template')
  if (!template) {
    return null
  }

  let description =
    button?.dataset?.turboConfirmDescription ||
    element.dataset.turboConfirmDescription ||
    ''

  let confirmButtonText =
    button?.dataset?.turboConfirmButtonText ||
    element.dataset.turboConfirmButtonText ||
    undefined

  let id = `confirm-dialog-${new Date().getTime()}`

  let content = template.content.firstElementChild.cloneNode(true)

  if (!content) {
    return null
  }

  content.innerHTML = content.innerHTML
    .replaceAll('{{ message }}', message)
    .replaceAll('{{ description }}', description)

  content.id = id

  if (confirmButtonText) {
    content.querySelector('button[value="confirm"]').textContent =
      confirmButtonText
  }

  document.body.insertAdjacentHTML('beforeend', content.outerHTML)
  let dialog_container = document.getElementById(id)

  return dialog_container
}

Turbo.config.forms.confirm = (message, element, button) => {
  let dialog_container = insertConfirmDialog(message, element, button)
  if (!dialog_container) {
    return window.confirm(message) // Fallback to native confirm dialog if template not found in DOM
  }

  let dialog = dialog_container.querySelector('dialog')

  queueMicrotask(() => {
    dialog.dispatchEvent(
      new CustomEvent('dialog:request-open', {
        bubbles: true,
        cancelable: true
      })
    )
  })

  return new Promise((resolve, reject) => {
    dialog.addEventListener(
      'close',
      () => {
        resolve(dialog.returnValue == 'confirm')
      },
      { once: true }
    )
  })
}
