import { Controller } from '@hotwired/stimulus'
import { DirectUpload } from '@rails/activestorage'
import { post } from '@rails/request.js'
import ChunkedUploader from '../../utils/chunked_uploader'

export default class extends Controller {
  static targets = [
    'filePreviewImage',
    'fileUploadField',
    'fileName',
    'fileSize',
    'stillFramePreviewImage',
    'stillFrameUploadField',
    'stillFrameContainer',
    'videoUploadId',
    'videoFilename', // hidden fields for Mux upload
    'createAssetViaApiField', // used as uploadField for parent controller to wait for the asset to be created via API
    'destroyField',
    'fileProgressBar',
    'stillFrameProgressBar'
  ]
  static values = {
    uploadOnDrop: {
      type: Boolean,
      default: false
    },
    uploadUrl: {
      type: String,
      default: '/rails/active_storage/direct_uploads'
    },
    newAssetEndpointUrl: {
      type: String,
      default: null
    },
    progressInfo: {
      type: Object,
      default: {
        file: { loaded: 0, total: 0 },
        stillFrame: { loaded: 0, total: 0 }
      }
    },
    muxUploadUrl: String
  }

  connect() {
    this.processIfNew()
  }

  disconnect() {
    this.teardownPlugins()
  }

  get file() {
    // The file is expected to be set as an attribute directly on the element by the parent controller.
    // It won't survive a page restore from a Turbo drive navigation.
    return this.element.file
  }

  get stillFrame() {
    // Similarly, for video files, we also allow setting a still image. Its File object is expected to
    // be set as an attribute directly on the element also, this one, from a UI element.
    return this.element.stillFrame
  }

  set stillFrame(stillFrame) {
    this.element.stillFrame = stillFrame
  }

  fileForField(uploadField) {
    if (
      this.hasFileUploadFieldTarget &&
      uploadField == this.fileUploadFieldTarget
    ) {
      return this.file
    } else if (
      this.hasStillFrameUploadFieldTarget &&
      uploadField == this.stillFrameUploadFieldTarget
    ) {
      return this.stillFrame
    }

    return null
  }

  previewImageForField(uploadField) {
    if (
      this.hasFilePreviewImageTarget &&
      uploadField == this.fileUploadFieldTarget
    ) {
      return this.filePreviewImageTarget
    } else if (
      this.hasStillFramePreviewImageTarget &&
      uploadField == this.stillFrameUploadFieldTarget
    ) {
      return this.stillFramePreviewImageTarget
    }

    return null
  }

  processIfNew() {
    if (this.hasFileUploadFieldTarget) {
      if (this.file) {
        this.updatePreviewFromFileForField(this.fileUploadFieldTarget)
        if (this.uploadOnDropValue) {
          this.uploadForField(this.fileUploadFieldTarget)
        }
      } else if (this.fileUploadFieldTarget.value === undefined) {
        this.remove()
      }
    }
  }

  remove() {
    if (this.hasDestroyFieldTarget) {
      this.destroyFieldTarget.value = true
    } else {
      if (this.hasFileUploadFieldTarget) {
        this.fileUploadFieldTarget.value = null
      }
      if (this.hasStillFrameUploadFieldTarget) {
        this.stillFrameUploadFieldTarget.value = null
      }
    }
    this.element.classList.add('hidden')
    this.dispatch('marked-for-removal')
  }

  updatePreviewFromFileForField(uploadField) {
    const file = this.fileForField(uploadField)
    if (!file) {
      return
    }

    if (
      this.isVideo(file) &&
      this.hasFilePreviewImageTarget &&
      uploadField == this.fileUploadFieldTarget
    ) {
      extractStillFrame(file)
        .then((stillFrame) => {
          this.stillFrame = stillFrame

          let previewImageTargets = [this.filePreviewImageTarget]
          if (this.hasStillFramePreviewImageTarget) {
            previewImageTargets.push(this.stillFramePreviewImageTarget)
          }
          this.updatePreviewImagesFromFileForTargets(
            stillFrame,
            previewImageTargets
          )

          if (this.hasStillFrameContainerTarget) {
            this.stillFrameContainerTarget.classList.remove('hidden')
          }

          if (this.uploadOnDropValue && this.hasStillFrameUploadFieldTarget) {
            this.uploadForField(this.stillFrameUploadFieldTarget)
          }
        })
        .catch((error) => {
          console.error('Failed to extract still frame:', error)
          this.dispatch('file-preview-error', { detail: { error } })
        })
    } else {
      this.updatePreviewImagesFromFileForTargets(file, [
        this.previewImageForField(uploadField)
      ])
    }

    if (
      this.hasFileUploadFieldTarget &&
      uploadField == this.fileUploadFieldTarget
    ) {
      this.updateFileDetails(file)
    }
  }

  updatePreviewImagesFromFileForTargets(file, previewImageTargets) {
    const imageUrl = URL.createObjectURL(file)

    previewImageTargets.forEach((previewImage) => {
      previewImage.src = imageUrl
      previewImage.alt = file?.name || ''
    })

    this.dispatch('file-preview-updated', {
      detail: { src: imageUrl, alt: file?.name || '' }
    })
  }

  updateFileDetails(file) {
    if (this.hasFileNameTarget) {
      this.fileNameTarget.textContent = file.name
    }
    if (this.hasFileSizeTarget) {
      this.fileSizeTarget.textContent = formatBytes(file.size)
    }
  }

  upload(event) {
    const uploadField = event?.currentTarget
    if (!uploadField) {
      return
    }

    this.uploadForField(uploadField)
  }

  uploadForField(uploadField) {
    if (this.isCreateAssetViaApi) {
      this.createAssetViaApiWhenFilesUploaded = true
      this.pretendCreateAssetViaApiFieldTargetIsAnUploadField()
    }

    const file = this.fileForField(uploadField)
    if (!file) {
      this.dispatchFromTarget(uploadField, 'upload-not-needed')
      return
    }

    this.dispatchFromTarget(uploadField, 'upload-start', { file })

    // Handle videos with Mux, everything else with ActiveStorage
    if (this.isVideo(file)) {
      this.uploadToMux(file, uploadField)
    } else {
      this.uploadToActiveStorage(file, uploadField)
    }
  }

  async uploadToMux(file, uploadField) {
    try {
      const response = await post(this.muxUploadUrlValue, {
        responseKind: 'json'
      })

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`)
      }

      const { url: uploadUrl, id } = await response.json

      this.muxUpload = new ChunkedUploader(file, uploadUrl, {
        chunkSize: 30720, // Uploads the file in ~30 MB chunks
        onProgress: (progress) => {
          const percent = progress.detail

          this.updateProgressInfo(
            {
              loaded: file.size * percent,
              total: file.size
            },
            uploadField
          )
        },
        onSuccess: () => {
          this.videoUploadIdTarget.value = id
          this.videoFilenameTarget.value = file.name
          uploadField.value = '' // Clear the file upload field

          this.treatUploadFieldAsCompleteWithPayload(uploadField, {
            video_upload_id: id
          })
        },
        onError: (error) => {
          console.error('Mux upload error:', error)
          this.dispatchFromTarget(uploadField, 'upload-error', { error })
        }
      })

      this.muxUpload.start()
    } catch (error) {
      console.error('Upload error:', error)
      this.dispatchFromTarget(uploadField, 'upload-error', { error })
    }
  }

  uploadToActiveStorage(file, uploadField) {
    const upload = new DirectUpload(file, this.uploadUrlValue, {
      directUploadWillStoreFileWithXHR: (xhr) => {
        xhr.upload.addEventListener('progress', (event) => {
          const { loaded, total } = event
          this.updateProgressInfo(
            { loaded: loaded * 100, total: total },
            uploadField
          )
        })
      }
    })

    upload.create((error, blob) => {
      if (error) {
        console.error(error)
        this.dispatchFromTarget(uploadField, 'upload-error', { error })
      } else {
        uploadField.value = blob.signed_id
        this.treatUploadFieldAsCompleteWithPayload(uploadField, { blob })
      }
    })
  }

  updateProgressInfo(event, uploadField) {
    const { loaded, total } = event
    const isMainFile = uploadField === this.fileUploadFieldTarget

    this.progressInfoValue = {
      ...this.progressInfoValue,
      [isMainFile ? 'file' : 'stillFrame']: { loaded, total }
    }
  }

  progressInfoValueChanged(newValue) {
    const totalLoaded = newValue.file.loaded + newValue.stillFrame.loaded
    const totalSize = newValue.file.total + newValue.stillFrame.total
    const percentages = {
      total: totalSize > 0 ? totalLoaded / totalSize : 0,
      file:
        newValue.file.total > 0
          ? newValue.file.loaded / newValue.file.total
          : 0,
      stillFrame:
        newValue.stillFrame.total > 0
          ? newValue.stillFrame.loaded / newValue.stillFrame.total
          : 0
    }

    this.dispatch('upload-progress', {
      detail: { progress: percentages.total }
    })

    if (this.hasFileProgressBarTarget) {
      this.fileProgressBarTarget.dispatchEvent(
        new CustomEvent('update-progress', {
          detail: { progress: percentages.file }
        })
      )
    }

    if (this.hasStillFrameProgressBarTarget) {
      this.stillFrameProgressBarTarget.dispatchEvent(
        new CustomEvent('update-progress', {
          detail: { progress: percentages.stillFrame }
        })
      )
    }
  }

  treatUploadFieldAsCompleteWithPayload(uploadField, payload) {
    this.dispatchFromTarget(uploadField, 'upload-completed', payload)

    if (this.createAssetViaApiWhenFilesUploaded && this.allFilesUploaded) {
      this.createAssetViaApi()
    }
  }

  pretendCreateAssetViaApiFieldTargetIsAnUploadField() {
    this.dispatchFromTarget(this.createAssetViaApiFieldTarget, 'upload-start')
  }

  get isCreateAssetViaApi() {
    return this.hasCreateAssetViaApiFieldTarget
  }

  async createAssetViaApi() {
    if (!this.hasNewAssetEndpointUrlValue) {
      console.error('No new asset endpoint URL provided')
      return
    }

    const params = {
      asset: {}
    }

    // Add file if available
    if (this.hasFileUploadFieldTarget && this.fileUploadFieldTarget.value) {
      params.asset.file = this.fileUploadFieldTarget.value
    }

    // Add still frame if available
    if (
      this.hasStillFrameUploadFieldTarget &&
      this.stillFrameUploadFieldTarget.value
    ) {
      params.asset.still_frame = this.stillFrameUploadFieldTarget.value
    }

    if (this.hasVideoUploadIdTarget && this.videoUploadIdTarget.value) {
      params.asset.video_upload_id = this.videoUploadIdTarget.value
    }

    try {
      const response = await post(this.newAssetEndpointUrlValue, {
        body: params
      })

      if (response.ok) {
        const data = await response.json
        if (this.hasCreateAssetViaApiFieldTarget) {
          this.createAssetViaApiFieldTarget.value = data.id
          this.dispatchFromTarget(
            this.createAssetViaApiFieldTarget,
            'upload-completed'
          )
          this.dispatchFromTarget(
            this.createAssetViaApiFieldTarget,
            'asset-created',
            { asset: data }
          )
        }
      }
    } catch (error) {
      console.error('Error creating asset:', error)
      if (this.hasCreateAssetViaApiFieldTarget) {
        this.dispatchFromTarget(
          this.createAssetViaApiFieldTarget,
          'upload-error',
          { error }
        )
      }
    }
  }

  replaceStillFrame(event) {
    if (!this.hasStillFramePreviewImageTarget) {
      return
    }
    if (!this.hasStillFrameContainerTarget) {
      return
    }

    let files = []
    if (event.type === 'drop') {
      event.stopPropagation()
      files = this.filterFilesByImages(event.dataTransfer.files)
    } else if (event.type === 'change') {
      event.preventDefault()
      files = this.filterFilesByImages(event.target.files)
      event.target.value = '' // reset the file input
    } else {
      return
    }

    if (files.length === 0) {
      return
    }

    this.stillFrame = files[0]

    this.updatePreviewImagesFromFileForTargets(this.stillFrame, [
      this.stillFramePreviewImageTarget
    ])

    if (this.uploadOnDropValue && this.hasStillFrameUploadFieldTarget) {
      this.uploadForField(this.stillFrameUploadFieldTarget)
    }
  }

  filterFilesByImages(files) {
    return Array.from(files).filter((file) => {
      return file.type.startsWith('image/')
    })
  }

  isVideo(file) {
    return file.type.startsWith('video/')
  }

  dispatchFromTarget(target, eventName, details) {
    const event = new CustomEvent(`${this.identifier}:${eventName}`, {
      bubbles: true,
      cancelable: true,
      detail: details
    })
    target.dispatchEvent(event)
  }

  get allFilesUploaded() {
    return this.isFileUploaded && this.isStillFrameUploaded
  }

  get isFileUploaded() {
    if (!this.hasFileUploadFieldTarget) {
      return true
    }
    if (this.isVideo(this.file)) {
      return (
        this.hasVideoUploadIdTarget &&
        this.videoUploadIdTarget.value !== '' &&
        this.videoUploadIdTarget.value !== undefined
      )
    } else {
      return this.fileUploadFieldTarget.value !== undefined
    }
  }

  get isStillFrameUploaded() {
    if (!this.hasStillFrameUploadFieldTarget) {
      return true
    }
    return this.stillFrameUploadFieldTarget.value !== undefined
  }

  teardownPlugins() {
    if (this.muxUpload) {
      this.muxUpload.destroy()
    }
  }
}

function formatBytes(bytes, decimals = 1) {
  if (bytes === 0) return '0 Bytes'
  const k = 1024
  const dm = decimals < 0 ? 0 : decimals
  const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB']
  const i = Math.floor(Math.log(bytes) / Math.log(k))
  return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i]
}

async function extractStillFrame(videoFile) {
  return new Promise((resolve, reject) => {
    const videoElement = document.createElement('video')
    videoElement.src = URL.createObjectURL(videoFile)

    videoElement.addEventListener(
      'loadeddata',
      () => {
        const canvas = document.createElement('canvas')
        canvas.width = videoElement.videoWidth
        canvas.height = videoElement.videoHeight
        const ctx = canvas.getContext('2d')

        videoElement.addEventListener(
          'seeked',
          () => {
            ctx.drawImage(videoElement, 0, 0, canvas.width, canvas.height)
            canvas.toBlob((blob) => {
              const stillFrameFile = new File(
                [blob],
                videoFile.name.replace(/\.[^/.]+$/, '') + '-still-image.jpeg',
                { type: 'image/jpeg' }
              )
              resolve(stillFrameFile)
            }, 'image/jpeg')
          },
          { once: true }
        )

        videoElement.currentTime = 0.1 // Grab the frame at 0.1 second
      },
      { once: true }
    )

    videoElement.onerror = reject
  })
}
