import {
  ElementDecoratorController,
  elementAllowsChildNodes,
  wrapElement,
  wrapElements
} from '../../utils/element_decorator'

// allows the on-demand creation of a spinner inside (or around) an element with the following attribute
const spinnerRequestedAttribute = 'data-create-spinner'
const spinnerContainerAttribute = 'data-spinner-container'

const spinnerContainerClass = 'group/button'
const spinnerShownAttribute = 'data-spinner-shown'
const buttonContentsWrapperClass =
  'btn-internal-layout opacity-100 group-data-[spinner-shown=true]/button:opacity-0 transition-[opacity_0ms,width_300ms] delay-[inherit] w-[var(--content-normal-width,auto)] group-data-[spinner-shown=true]/button:w-[var(--content-max-width,auto)]'
const spinnerTemplate = `
  <div
    data-behavior="spinner-overlay"
    class="
      absolute btn-internal-layout flex pointer-events-none items-center justify-center overflow-hidden rounded-inherit
      opacity-0 group-data-[spinner-shown=true]/button:opacity-100 transition duration-0 delay-[inherit]
    "
  >
    <span class="dotlottie-wrapper relative">
      <dotlottie-wc
        class="absolute w-full h-full leading-none left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2"
        src="SPINNER_ASSET_PATH"
        speed="1"
        loop
      ></dotlottie-wc>
    </span>
    LOADING_TEXT
  </div>
`

export default class extends ElementDecoratorController {
  static classes = ['spinner']
  static values = {
    spinnerAssetPath: String
  }

  createSpinnerElement() {
    let html = spinnerTemplate.trim()

    html = html.replace('SPINNER_ASSET_PATH', this.spinnerAssetPathValue)

    const template = document.createElement('template')
    template.innerHTML = html
    return template.content.firstElementChild
  }

  connect() {
    this.instantiateSpinnerWithinOrAround =
      this.instantiateSpinnerWithinOrAround.bind(this)
    this.startDecoratingElementsAutomatically(
      spinnerRequestedAttribute,
      this.instantiateSpinnerWithinOrAround
    )
  }

  disconnect() {
    this.stopDecoratingElements()
  }

  showSpinnerOnSubmitter(event) {
    const { submitter } = event.detail?.formSubmission
    if (!submitter) return

    const container = submitter.closest(`[${spinnerShownAttribute}]`)
    if (!container) return

    this.showSpinnerIn(container)
  }

  keepSpinnersForEvent(event) {
    event.detail.keepSpinners = true
  }

  hideSpinnersOfForm(event) {
    if (!event || !event.target) return
    const form = event.target

    if (event.detail?.keepSpinners) return

    const spinnerContainers = this.spinnersOfForm(form)
    spinnerContainers.forEach((container) => {
      this.hideSpinnerIn(container)
    })
  }

  spinnersOfForm(form) {
    if (!form) return []

    const formId = form.id

    // Find buttons that are direct children of the form
    const childButtons = form.querySelectorAll(
      `[${spinnerShownAttribute}=true]`
    )

    // Find buttons elsewhere on the page that reference this form via form attribute
    const externalButtons = formId
      ? document.querySelectorAll(
          `[form="${formId}"][${spinnerShownAttribute}=true]`
        )
      : []

    // Combine all spinner containers
    return [...Array.from(childButtons), ...Array.from(externalButtons)]
  }

  showSpinnerIn(container) {
    if (!container) return
    container.setAttribute(spinnerShownAttribute, 'true')
    container.querySelector('dotlottie-wc')?.dotLottie?.play()
  }

  hideSpinnerIn(container) {
    if (!container) return
    container.setAttribute(spinnerShownAttribute, 'false')
    container.querySelector('dotlottie-wc')?.dotLottie?.stop()
  }

  instantiateSpinnerWithinOrAround(button) {
    if (elementAllowsChildNodes(button)) {
      this.transformAndAddSpinnerTo(button)
    } else if (button instanceof HTMLInputElement) {
      this.createSpinnerButtonAround(button)
    } else {
      console.warn(
        `${this.identifier}: button element is not an HTMLInputElement or an element that allows child nodes`
      )
    }
  }

  transformAndAddSpinnerTo(button) {
    // idempotency: if the element already has a spinner container, don't override it
    if (button.hasAttribute(spinnerContainerAttribute)) return
    button.removeAttribute(spinnerRequestedAttribute)

    this.isolateButtonContentsOf(button)
    this.instantiateSpinnerWithin(button)
  }

  createSpinnerButtonAround(inputButton) {
    // idempotency: if the element already has a spinner container, don't override it
    if (
      inputButton.hasAttribute(spinnerContainerAttribute) ||
      inputButton.parentElement.hasAttribute(spinnerContainerAttribute)
    ) {
      return
    }
    inputButton.removeAttribute(spinnerRequestedAttribute)

    // Create wrapper which will server as a styled button
    const fakeButton = wrapElement(inputButton, 'span')
    fakeButton.setAttribute('tabindex', '-1') // prevent focus on the fake button

    // Move button classes to the wrapper
    const buttonClasses = inputButton.classList
    fakeButton.classList.add(...buttonClasses)
    inputButton.classList.remove(...buttonClasses)

    // Reproduce the input button's text in a new span, so the size of the button is preserved
    const buttonText = inputButton.value
    const buttonTextSpan = document.createElement('span')
    buttonTextSpan.textContent = buttonText
    buttonTextSpan.classList.add(...buttonContentsWrapperClass.split(' '))
    fakeButton.insertBefore(buttonTextSpan, fakeButton.firstChild)

    // Make the original input absolutely positioned inside the wrapper, opacity 0
    inputButton.style.position = 'absolute'
    inputButton.style.inset = '0'
    inputButton.style.opacity = '0'
    inputButton.style.borderRadius = 'inherit'

    if (inputButton.hasAttribute('data-loading-text')) {
      const loadingText = inputButton.getAttribute('data-loading-text')
      fakeButton.setAttribute('data-loading-text', loadingText)
    }

    this.instantiateSpinnerWithin(fakeButton)
  }

  instantiateSpinnerWithin(button) {
    const spinner = this.createSpinnerElement()

    const loadingText = button.getAttribute('data-loading-text')
    spinner.innerHTML = spinner.innerHTML.replace(
      'LOADING_TEXT',
      loadingText || ''
    )

    button.appendChild(spinner)
    button.setAttribute(spinnerContainerAttribute, '')

    button.classList.add(...spinnerContainerClass.split(' '))

    // idempotency: if the element already has a spinnerShownAttribute, don't override it
    if (!button.getAttribute(spinnerShownAttribute)) {
      button.setAttribute(spinnerShownAttribute, 'false')
    }

    this.setWidthCssVariablesOf(button)

    return button
  }

  isolateButtonContentsOf(element) {
    const wrapper = wrapElements(element.childNodes)
    if (!wrapper) return
    wrapper.classList.add(...buttonContentsWrapperClass.split(' '))
  }

  setWidthCssVariablesOf(container) {
    const { normalWidth, maxWidth } = this.calcualateNormalMaxWidthOf(container)
    // set two css variables: --content-normal-width and --content-max-width
    container.style.setProperty('--content-normal-width', `${normalWidth}px`)
    container.style.setProperty('--content-max-width', `${maxWidth}px`)
  }

  calcualateNormalMaxWidthOf(container) {
    const children = Array.from(container.children).filter(
      (c) => !c.matches('input[type="submit"]')
    )

    const maxWidth = Math.max(...children.map((c) => c.offsetWidth))
    const childrenExcludingSpinnerOverlay = children.filter(
      (c) => !c.matches('[data-behavior="spinner-overlay"]')
    )
    const normalWidth = Math.max(
      ...childrenExcludingSpinnerOverlay.map((c) => c.offsetWidth)
    )
    return { normalWidth, maxWidth }
  }

  // not to be used too often, because showing the spinner should default to being triggered
  // by a form submission that's caught by turbo (e.g. turbo:submit-start, stopped on turbo:submit-end)
  showSpinner(event) {
    this.showSpinnerIn(this.spinnerContainerFor(event.currentTarget))
  }

  hideSpinner(event) {
    this.hideSpinnerIn(this.spinnerContainerFor(event.currentTarget))
  }

  // for debugging purposes
  debugSpinnerBriefly(event) {
    event.preventDefault()
    event.stopPropagation()

    const spinnerContainer = this.spinnerContainerFor(event.currentTarget)
    if (!spinnerContainer) return

    this.showSpinnerIn(spinnerContainer)
    setTimeout(() => {
      this.hideSpinnerIn(spinnerContainer)
    }, 3000)
  }

  spinnerContainerFor(element) {
    return element.closest(`[${spinnerContainerAttribute}]`)
  }
}
