import { Controller } from '@hotwired/stimulus'

// like clipboard, but shows/hides a different div when the link is copied
// useful if the "Copied" state is not just text, but and HTML element

// <div
//   data-controller="clipboard-block"
//   data-clipboard-block-success-duration-value="4000"
//   data-clipboard-block-source-value="https://www.google.com"
// >
//   <div role="button" data-clipboard-block-target="button" data-action="click->clipboard-block#copy">Copy</div>
//   <div data-clipboard-block-target="timeoutButton" hidden>Copied!</div>
// </div>

// Connects to data-controller="clipboard-block"
export default class extends Controller {
  static targets = ['button', 'timeoutButton']
  static values = {
    successDuration: { type: Number, default: 2000 },
    source: { type: String }
  }

  connect() {
    this.timeoutButtonTarget.hidden = true
  }

  copy(event) {
    event.preventDefault()

    let value = this.sourceValue
    this.copyToClipboard(value)

    this.buttonTarget.hidden = true
    this.timeoutButtonTarget.hidden = false

    // Reset buttons after successDuration
    setTimeout(() => {
      this.buttonTarget.hidden = false
      this.timeoutButtonTarget.hidden = true
    }, this.successDurationValue)
  }

  async copyToClipboard(text) {
    try {
      await navigator.clipboard.writeText(text)
    } catch (err) {
      console.error('Failed to copy text: ', err)
    }
  }
}
