import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = [
    'wrapper',
    'builder',
    'form',
    'previewWrapper',
    'previewTurboFrame',
    'iframe',
    'loader'
  ]

  static values = {
    currentSection: String
  }

  connect() {
    this.focusedElement = null
    this.preventPublish = false

    if (this.hasPreviewTurboFrameTarget) {
      this.setupPreviewMessageHandler()
      this.initializePreviewSections()
    }
  }

  disconnect() {
    if (this.hasPreviewTurboFrameTarget) {
      window.removeEventListener('message', this.handleMessage)
    }
  }

  initializePreviewSections() {
    if (!this.hasIframeTarget) {
      return
    }

    const iframe = this.iframeTarget
    const sections = iframe.contentDocument.querySelectorAll('[data-section]')

    sections.forEach((section) => {
      section.addEventListener('click', (event) => {
        event.preventDefault()
        event.stopPropagation()
        this.handleSectionClick(section.dataset.section)
      })
    })
  }

  handleSectionClick(sectionName) {
    const url = new URL(window.location.href)
    url.searchParams.set('section', sectionName)
    this.builderTarget.src = url.toString()
    this.currentSectionValue = sectionName
    // Turbo.visit(url.toString(), { turbo_frame: "edit_sales_page" })
  }

  handleFormSectionClick(event) {
    const clicked = event.target
    const target = event.currentTarget

    if (
      ['input', 'textarea', 'select'].includes(clicked.tagName.toLowerCase())
    ) {
      return // don't scroll on input elements
    }
    const section = target.getAttribute('data-section')
    if (section === this.currentSectionValue) {
      return // don't scroll to same section repeatedly
    }

    this.currentSectionValue = section

    this.highlightActiveSection()
    this.scrollIframeToCurrentSection()
  }

  handleFocus(event) {
    this.focusedElement = event.target
  }

  handleBlur(event) {
    this.focusedElement = null
  }

  handleKeyUp(event) {
    if (event.key === 'Tab') {
      this.preventPublish = true
    } else {
      this.preventPublish = false
    }
  }

  submitStart() {
    this.loaderTarget.setAttribute('disabled', true)
  }

  submitEnd() {
    this.loaderTarget.removeAttribute('disabled')
    this.updatePreview()

    this.highlightActiveSection()
  }

  allowPublish() {
    if (!this.focusedElement) {
      return true
    }

    if (
      this.preventPublish ||
      this.focusedElement.getAttribute('contenteditable') ||
      ['input', 'textarea', 'select'].includes(
        this.focusedElement.tagName.toLowerCase()
      )
    ) {
      return false
    }

    return true
  }

  publish() {
    setTimeout(() => {
      if (this.allowPublish()) {
        document.getElementById('publish_page').click()
      }
    }, 100)
  }

  scrollBuilder() {
    if (
      !(this.builderTarget && this.builderTarget.src) ||
      this.isScrollingBuilder
    ) {
      return
    }

    this.isScrollingBuilder = true

    const frameUrl = new URL(this.builderTarget.src)
    const section = frameUrl.searchParams.get('section')

    // not sure if we need this or not
    // if (section === this.currentSectionValue) {
    //   console.log("don't scroll to same section repeatedly")
    //   return
    // }

    this.currentSectionValue = section

    const element = document.querySelector(`#builder_section_${section}`)

    if (element) {
      this.wrapperTarget.scrollTo({
        top: element.offsetTop - this.wrapperTarget.offsetTop,
        behavior: 'smooth'
      })
    }

    this.isScrollingBuilder = false
  }

  updatePreview() {
    this.iframeTarget.contentWindow.postMessage(
      'update',
      window.location.origin
    )
  }

  setupPreviewMessageHandler() {
    window.addEventListener('message', (event) => {
      if (event.origin === window.location.origin) {
        if (event.data === 'update') {
          Turbo.visit(window.location.href, {
            frame: 'preview',
            action: 'replace'
          })
        }

        if (event.data.focusedSection) {
          this.handleFocusedSection(event.data)
        }
      }
    })
  }

  handleFocusedSection(data) {
    document.getElementById('focused-section-style').innerHTML = `
      [data-section] {
        position: relative;
        cursor: pointer;
      }
      div[data-section]::before {
        content: attr(data-section-name);
        background-image: var(--section-icon-white);
        background-repeat: no-repeat;
        background-position: 10px center;
        position: absolute;
        font-size: 28px;
        z-index: 1000;
        top: 0;
        transform: translateY(-100%);
        left: 0;
        display: block;
        background-color: #43966C;
        border: 2px solid #43966C;
        border-top-left-radius: 12px;
        border-top-right-radius: 12px;
        border-bottom: none;
        color: #fff;
        padding: 4px 16px 4px 56px;
        opacity: 0;
      }
      
      div[data-section]::after {
        height: calc(100%);
        width: 100%;
        position: absolute;
        z-index: 1000;
        top: 0;
        left: 0;
        display: block;
        content: "";
        margin: 0
        opacity: 0;
      }
      div[data-section]:not([data-section=${
        data.focusedSection
      }]):hover::before {
        opacity: 1;
      }
      div[data-section]:not([data-section=${
        data.focusedSection
      }]):hover::after {
        opacity: 1;
        box-shadow: inset 0px 0px 0px 3px #43966C;
      }

      [data-section='${data.focusedSection}'] {
        ${data.focusedSection == 'header' ? 'margin-top: 32px;' : ''}
      }
      [data-section='${data.focusedSection}']::before {
        top: ${data.focusedSection == 'header' ? '29' : '0'}px !important;
        background-color: #43966C !important;
        border: 3px solid #43966C !important;
        color: #fff !important;
        opacity: 1 !important;
      }
      [data-section='${data.focusedSection}']::after {
        box-shadow: inset 0px 0px 0px 3px #43966C !important;
        margin: ${
          data.focusedSection == 'header' ? '29' : '0'
        }px 0 0 0 !important;
        opacity: 1 !important;
      }
    `
  }

  scrollIframeToCurrentSection() {
    if (
      !this.iframeTarget ||
      !this.currentSectionValue ||
      this.isScrollingIframe
    ) {
      return
    }

    this.isScrollingIframe = true

    const section = this.currentSectionValue

    var iframe = this.iframeTarget

    const targetElement = iframe.contentWindow.document.querySelector(
      `[id='${section}']`
    )

    if (targetElement) {
      if (section != 'header') {
        const headerOffset =
          iframe.contentWindow.document.querySelector(
            '[id="header"]'
          ).offsetHeight

        // Get element's position relative to the iframe
        const elementRect = targetElement.getBoundingClientRect()

        // Calculate the total scroll position needed
        const scrollPosition =
          elementRect.top + // Element's position within iframe
          iframe.contentWindow.pageYOffset - // Current iframe scroll position
          headerOffset // Header offset if needed

        iframe.contentWindow.scrollTo({
          top: scrollPosition,
          behavior: 'smooth'
        })
      } else {
        iframe.contentWindow.scrollTo({
          top: 0,
          behavior: 'smooth'
        })
      }
    } else {
      console.error(`Element with selector [id='${section}'] not found.`)
    }

    this.isScrollingIframe = false
  }

  highlightActiveSection() {
    if (!this.hasCurrentSectionValue) {
      return
    }

    const section = this.currentSectionValue

    if (this.hasIframeTarget) {
      const iframe = this.iframeTarget

      const message = {
        focusedSection: section
      }

      if (iframe.getAttribute('data-focused')) {
        iframe.setAttribute('data-focused', section)
        iframe.contentWindow.postMessage(message, window.location.origin)
      } else {
        // wait for iframe to load
        iframe.addEventListener('load', () => {
          iframe.setAttribute('data-focused', section)
          iframe.contentWindow.postMessage(message, window.location.origin)
        })
      }
    }
  }
}
