import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["progress", "input"]

  connect() {
    // Initialize progress on connect
    this.updateProgress()
  }

  input() {
    this.updateProgress()
  }

  updateProgress() {
    const input = this.inputTarget
    const progress = (input.value.length / input.maxLength) * 100
    // Subtract from 100 to make the circle fill clockwise
    this.progressTarget.style.strokeDashoffset = `${100 - progress}`
  }
}
