import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['container', 'overlay', 'content']

  connect() {
    document.addEventListener('keydown', this.handleKeyDown.bind(this))
  }

  disconnect() {
    document.removeEventListener('keydown', this.handleKeyDown.bind(this))
  }

  handleKeyDown(event) {
    if (event.key === 'Escape') this.close()
  }

  open(event) {
    event.preventDefault()
    event.stopPropagation()

    const videoId = event.currentTarget.dataset.videoId
    if (!videoId) return

    // First show modal
    this.containerTarget.classList.remove('hidden')
    document.body.style.overflow = 'hidden'

    // Then load video player
    this.loadVideoPlayer(videoId)
  }

  async loadVideoPlayer(videoId) {
    try {
      const response = await fetch(
        `/assets/${videoId}/video_player?play_button_type=play-button-large`
      )
      if (!response.ok) throw new Error('Failed to load video')

      const html = await response.text()

      // Update content and ensure Mux player is initialized
      this.contentTarget.innerHTML = html

      // Make sure Mux script is loaded
      if (!window.customElements.get('mux-player')) {
        await new Promise((resolve, reject) => {
          const script = document.createElement('script')
          script.src = 'https://cdn.jsdelivr.net/npm/@mux/mux-player'
          script.onload = resolve
          script.onerror = reject
          document.head.appendChild(script)
        })
      }
    } catch (error) {
      console.error('Error loading video:', error)
      this.contentTarget.innerHTML =
        '<div class="p-4 text-center">Failed to load video</div>'
    }
  }

  close() {
    // First try to pause any playing videos
    try {
      const players = this.contentTarget.querySelectorAll('mux-player')
      players.forEach((player) => {
        if (player && typeof player.pause === 'function') {
          player.pause()
        }
      })
    } catch (e) {
      console.error('Error pausing video:', e)
    }

    // Then close modal
    this.containerTarget.classList.add('hidden')
    document.body.style.overflow = ''

    // Clear content after a short delay to ensure clean teardown
    setTimeout(() => {
      this.contentTarget.innerHTML = ''
    }, 100)
  }
}
