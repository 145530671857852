import { Controller } from "@hotwired/stimulus"
import SlidersReportionPlan from "../../utils/sliders_reportion_plan"

export default class extends Controller {
  static targets = ["sliderField", "numberField"]

  static values = {
    reportionedEventName: {
      type: String,
      default: "slider-set:reportioned" // dispatched on the input that was reportioned, to sync up numbers and range inputs
    }
  }

  connect() {
    this.getReadyForReportioning()
  }

  disconnect() {
    this.teardownReportioning()
  }

  getReadyForReportioning() {
    this.totalToEnsure = this.sliderFieldTargets.reduce((total, input) => total + Number(input.value), 0)
  }

  teardownReportioning() {
    this.totalToEnsure = undefined
    this.concludeReportion() // just in case a change event wasn't fired by the time we disconnect
  }

  // To deal with rounding values, and get the feel right on incremental changes (small increments, typed-in numbers)
  // we track initial values and the rounding step. This way, the reportioning will feel more natural until the user is done.

  // A reportion starts with the first `input` event, and concludes with the `change` event on the same input.
  startReportioning(eventTarget) { // called on first input event on reportionOtherFields()
    this.saveInitialValuesAndChangedField(eventTarget)
    this.saveRoundingStep(eventTarget)
    this.startedReportion = true
  }

  concludeReportion() { // on change event
    this.cleanupSavedValues()
    this.startedReportion = undefined
  }

  reportionOtherFields(event) {
    if (!this.startedReportion) {
      this.startReportioning(event.target)
    }

    if (!this.initialValues) { console.error("initialValues are not set"); return; }
    if (!this.roundingStep) { console.error("roundingStep is not set"); return; }

    const changedField = this.initialValues.changedField?.field
    if (event.target !== changedField ) { return } // would be weird
    
    const changedFieldOriginalValue = this.initialValues.changedField?.value
    const changedFieldNewValue = Number(this.roundFieldValue(changedField))
    const otherFieldsOriginalValues = this.initialValues.otherInputs.map(input => input.value)

    // We use a reportion plan to decide how to distribute the incremental change across all other inputs
    // taking into account rounding of values as per the input's `step` attribute (e.g. `step="5"`).
    
    const reportionPlan = new SlidersReportionPlan(
      changedFieldOriginalValue,
      changedFieldNewValue,
      otherFieldsOriginalValues,
      this.roundingStep
    )
    if (!reportionPlan) { console.error("reportionPlan is not set"); return; }

    reportionPlan.perform((otherFieldIndex, newValue) => {
      const field = this.initialValues.otherInputs[otherFieldIndex].field
      field.value = newValue
      field.dispatchEvent(new CustomEvent(this.reportionedEventNameValue))
    })
  }

  saveInitialValuesAndChangedField(eventTarget) {
    const [changedField, otherInputs] = this.isolateCurrentFromOtherInputs(eventTarget)

    if (!this.totalToEnsure) { console.error("totalToEnsure is not set"); return; }

    const originalChangedFieldValue = this.totalToEnsure - otherInputs.reduce((sum, input) => sum + Number(input.value), 0)

    this.initialValues = {
      otherInputs: otherInputs.map(input => { return { field: input, value: Number(input.value) } }),
      changedField: { field: changedField, value: originalChangedFieldValue }
    }
  }

  saveRoundingStep(eventTarget) {
    if (!this.initialValues) { console.error("initialValues are not set"); return; }

    const changedField = this.initialValues.changedField?.field
    this.roundingStep = Number(changedField.step) || 1
  }

  cleanupSavedValues() {
    this.initialValues = undefined
    this.roundingStep = undefined
  }

  // expect the event to be caught at the slider_set level
  isolateCurrentFromOtherInputs(eventTarget) {
    let currentInput = undefined
    let otherInputs = []

    let inputs = this.numberFieldTargets
    if (eventTarget.type === "range") {
      inputs = this.sliderFieldTargets
    }

    inputs.forEach(input => {
      if (input === eventTarget) {
        currentInput = input
      } else {
        otherInputs.push(input)
      }
    })

    return [currentInput, otherInputs]
  }

  roundFieldValue(field) {
    const value = Number(field.value)
    const step = Number(field.step) || 1
    return Math.round(value / step) * step
  }
}
