export default class SlidersReportionPlan {
  constructor(changedFieldOriginalValue, changedFieldNewValue, otherFieldsOriginalValues, roundingStep) {
    this.changedFieldOriginalValue = changedFieldOriginalValue
    this.changedFieldNewValue = changedFieldNewValue
    this.otherFieldsOriginalValues = otherFieldsOriginalValues
    this.roundingStep = roundingStep

    this.totalToEnsure = this.otherFieldsOriginalValues.reduce((sum, value) => sum + value, 0) + this.changedFieldOriginalValue

    this.otherFieldsNewValues = []
  }

  prepare() {
    const delta = this.changedFieldNewValue - this.changedFieldOriginalValue
    let remainingToReportion = delta
    let numFieldsRemaining = this.otherFieldsOriginalValues.length

    let adjustmentForEachField = -1 * (remainingToReportion / numFieldsRemaining)

    // some fields can't take the full adjustment, so we'll have their value set to the max or min
    // and so we'll skip them from the dynamic reportion, and subtract their value from the remaining to reportion
    const indexesToSkip = this.indexesOfFieldsThatCantTakeFullAdjustment(adjustmentForEachField)
    if (indexesToSkip.length > 0) {
      const sumOfSkippedFields = indexesToSkip.reduce((sum, index) => sum + this.otherFieldsOriginalValues[index], 0)

      numFieldsRemaining -= indexesToSkip.length
      remainingToReportion -= sumOfSkippedFields

      if (numFieldsRemaining > 0) {
        adjustmentForEachField = -1 * (remainingToReportion / numFieldsRemaining)
      } else {
        this.otherFieldsNewValues = this.otherFieldsOriginalValues
        return
      }
    }

    this.otherFieldsNewValues = this.otherFieldsOriginalValues.map((originalValue, index) => {
      if (indexesToSkip.includes(index)) {
        if (adjustmentForEachField > 0) {
          return this.totalToEnsure
        } else if (adjustmentForEachField < 0) {
          return 0
        }
      }

      // for the fields that can take the full adjustment, we'll apply a rounded value to each field
      // and then update the remaining to reportion and the number of fields remaining
      let newValue = originalValue + this.roundChange(adjustmentForEachField, this.roundingStep)

      remainingToReportion -= (originalValue - newValue)
      numFieldsRemaining -= 1
      if (numFieldsRemaining > 0) {
        adjustmentForEachField = -1 * (remainingToReportion / numFieldsRemaining)
      }
      
      return newValue
    })
  }

  perform(callbackPerInput) {
    this.prepare()
    this.otherFieldsNewValues.forEach((newValue, otherFieldIndex) => {
      callbackPerInput?.(otherFieldIndex, newValue)
    })
  }

  roundChange(change, nearestStep) {
    // We want to avoid rounding to 0, in either direction,
    // because we want to give the first input to reportion the bigger share of the change
    const roundFunction = change > 0 ? Math.ceil : Math.floor
    
    // If the change is 0, we don't need to round it
    if (change === 0) { return 0 }

    return roundFunction(change / nearestStep) * nearestStep
  }

  indexesOfFieldsThatCantTakeFullAdjustment(intendedAdjustment) {
    const min = 0
    const max = this.totalToEnsure

    let indexesToSkip = []

    this.otherFieldsOriginalValues.map((value, index) => {
      if (value + intendedAdjustment < min || value + intendedAdjustment > max) {
        indexesToSkip.push(index)
      }
    })

    return indexesToSkip
  }
}

