import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static values = {
    params: Array
  }

  updateParamsInUrl(event) {
    const frame = event.target
    const frameUrl = new URL(frame.src)
    const currentUrl = new URL(window.location.href)

    let urlChanged = false

    this.paramsValue.forEach((param) => {
      const multiple = frameUrl.searchParams.has(`${param}[]`)

      if (multiple) {
        param = `${param}[]`
        const values = this.allSearchParamsValuesOf(frameUrl, param)

        currentUrl.searchParams.delete(param)

        values.forEach((value) => {
          currentUrl.searchParams.append(param, value)
          urlChanged = true
        })
      } else {
        const value = this.lastSearchParamsValueOf(frameUrl, param)
        if (value !== undefined) {
          currentUrl.searchParams.set(param, value)
          urlChanged = true
        }
      }
    })

    if (urlChanged) {
      history.replaceState({}, '', currentUrl.toString())
    }

    this.dispatch('page-url-changed', {
      detail: { url: currentUrl.toString() }
    })
  }

  lastSearchParamsValueOf(url, param) {
    const values = this.allSearchParamsValuesOf(url, param)
    if (values.length === 0) return undefined
    return values[values.length - 1]
  }

  allSearchParamsValuesOf(url, param) {
    return url.searchParams.getAll(param)
  }
}
