import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['tab', 'panel']
  static values = {
    selectedTabIndex: {
      type: Number,
      default: 0
    }
  }

  selectTab(event) {
    if (event.params && event.params.tabIndex !== undefined) {
      this.selectedTabIndex = event.params.tabIndex
      return
    }

    const tab = event.currentTarget
    const tabIndex = this.tabTargets.findIndex((target) => target === tab)
    this.selectedTabIndex = tabIndex
  }

  selectedTabIndexValueChanged() {
    this.updateLayout()
  }

  updateLayout() {
    this.updateTabAriaAttributes() // styling is handled by the aria attributes
    this.updatePanelVisibility() // visibility is handled by the hidden attribute
    this.focusOnPanelOrFirstAutofocusField()
  }

  updateTabAriaAttributes() {
    this.tabTargets.forEach((target, index) => {
      target.setAttribute('aria-selected', index === this.selectedTabIndex)
    })
  }

  updatePanelVisibility() {
    this.panelTargets.forEach((target, index) => {
      target.hidden = this.selectedTabIndex !== index
    })
  }

  focusOnPanelOrFirstAutofocusField() {
    const panel = this.panelTargets[this.selectedTabIndex]

    const autofocusField = panel.querySelector('[autofocus]')

    if (autofocusField) {
      autofocusField.focus()
    } else {
      panel.focus()
    }
  }

  get selectedTabIndex() {
    return this.selectedTabIndexValue
  }

  set selectedTabIndex(value) {
    this.selectedTabIndexValue = value
  }
}
