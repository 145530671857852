import { Controller } from '@hotwired/stimulus'

/**
 * Base controller and utility functions for handling DOM element decoration and observation (e.g. tooltips, spinners).
 * Provides common functionality for:
 * - Observing DOM changes for elements matching a given attribute
 * - Cleanup on disconnect
 * - Element wrapping/decoration
 */
export class ElementDecoratorController extends Controller {
  startDecoratingElementsAutomatically(matchingAttributeName, callback) {
    this.element
      .querySelectorAll(`[${matchingAttributeName}]`)
      .forEach((element) => {
        callback(element)
      })

    this.startObservingForAttribute(matchingAttributeName, callback)
  }

  stopDecoratingElements() {
    this.teardownObserver()
  }

  connect() {
    this.filterAttributeMutations = this.filterAttributeMutations.bind(this)
  }

  disconnect() {
    this.teardownObserver()
  }

  teardownObserver() {
    if (this.observer) {
      this.observer.disconnect()
      this.observer = null
    }
  }

  startObservingForAttribute(attributeName, callback) {
    this.observer = new MutationObserver((mutations) =>
      this.filterAttributeMutations(mutations, attributeName, callback)
    )

    this.observer.observe(this.element, {
      attributes: true,
      attributeFilter: [attributeName],
      childList: true, // Watch for added/removed nodes
      subtree: true
    })
  }

  filterAttributeMutations(mutations, attributeName, callback) {
    mutations.forEach((mutation) => {
      // Handle attribute changes
      if (
        mutation.type === 'attributes' &&
        mutation.attributeName === attributeName
      ) {
        callback(mutation.target)
      }

      // Handle new nodes
      if (mutation.type === 'childList') {
        mutation.addedNodes.forEach((node) => {
          if (node.nodeType === Node.ELEMENT_NODE) {
            // Check if the new element itself needs a tooltip
            if (node.hasAttribute(attributeName)) {
              callback(node)
            }
            // Check if any descendants need tooltips
            node.querySelectorAll(`[${attributeName}]`).forEach((element) => {
              callback(element)
            })
          }
        })
      }
    })
  }
}

export function elementAllowsChildNodes(element) {
  return !(
    (
      element instanceof HTMLInputElement ||
      element instanceof HTMLSelectElement ||
      element instanceof HTMLTextAreaElement ||
      element instanceof HTMLImageElement ||
      element instanceof HTMLBRElement ||
      element instanceof HTMLHRElement ||
      element instanceof SVGElement
    ) // we won't be creating tooltips on SVG elements
  )
}

export function wrapElements(elements, wrapperTag = 'span') {
  const elementArray =
    elements instanceof NodeList ? Array.from(elements) : elements

  if (!Array.isArray(elementArray) || elementArray.length === 0) return null

  const wrapper = document.createElement(wrapperTag)
  const firstElement = elementArray[0]

  firstElement.parentNode.insertBefore(wrapper, firstElement)
  elementArray.forEach((element) => wrapper.appendChild(element))

  return wrapper
}

export function wrapElement(element, wrapperTag = 'span') {
  return wrapElements([element], wrapperTag)
}

export function moveAttributesFromElementToWrapper(
  attributesToMove,
  element,
  wrapper
) {
  attributesToMove.forEach((attr) => {
    if (element.hasAttribute(attr)) {
      wrapper.setAttribute(attr, element.getAttribute(attr))
      element.removeAttribute(attr)
    }
  })
}
