import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  preventToggle(event) {
    const { attributeName } = event.detail

    // prevent closing open details elements during morph
    if (attributeName === 'open') event.preventDefault()

    // prevent morphing open `reveal` components during morph
    if (
      attributeName === 'class' &&
      !event.target.classList.contains('hidden')
    ) {
      event.preventDefault()
    }
  }
}
