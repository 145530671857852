import { Controller } from '@hotwired/stimulus'

// <div data-controller="video-thumbnail-preview">
//   <input
//     data-video-thumbnail-preview-target="input"
//     type="text"
//     data-action="input->video-thumbnail-preview#fetchThumbnail"
//     value="https://www.youtube.com/watch?v=RNaaODDtTLw"
//   >
//   <div data-video-thumbnail-preview-target="output"></div>
// </div>

// public vimeo video:
// https://vimeo.com/158115405
// https://vimeo.com/api/oembed.json?url=https://vimeo.com/158115405

// private vimeo video:
// https://vimeo.com/1041568836
// https://vimeo.com/api/v2/video/1041568836.json

// public youtube video:
// www.youtube.com/watch?v=944lk4JAdyg
// https://www.youtube.com/oembed?url=https://www.youtube.com/watch?v=944lk4JAdyg

// https://docs.wistia.com/docs/wistia-and-oembed
// public wistia video:
// https://clickfunnels-28.wistia.com/medias/661x8p4j6u
// https://fast.wistia.com/oembed?url=https://clickfunnels-28.wistia.com/medias/661x8p4j6u

// public voomly video:
// https://share.voomly.com/v/C8yNYAmy1VCEiIIZGoo3U9blDcjTGzDQmOdFFkoFPavYnQ3lN

export default class extends Controller {
  static targets = ['input', 'output']

  connect() {
    this.fetchThumbnail()
  }

  outputTargetConnected() {
    this.fetchThumbnail()
  }

  inputTargetConnected() {
    this.fetchThumbnail()
  }

  inputTargetChanged() {
    this.fetchThumbnail()
  }

  async fetchThumbnail() {
    const url = this.inputTarget.value
    if (!url) {
      this.clearOutputs()
      return
    }

    const videoProvider = this.detectVideoProvider(url)
    if (!videoProvider) {
      this.clearOutputs()
      return
    }

    // Special handling for Voomly that does not support oEmbed
    if (videoProvider === 'voomly') {
      this.outputTargets.forEach((target) => {
        target.innerHTML =
          '<div class="flex items-center justify-center text-center gap-2 text-content-secondary py-1 px-1.5 aspect-video rounded-lg"><img class="w-5 h-5" src="/voomly-logo.svg" alt="Voomly logo"> Voomly</div>'
      })
      return
    }

    try {
      const thumbnailUrl = await this.fetchOembedThumbnail(url, videoProvider)
      if (thumbnailUrl) {
        this.outputTargets.forEach((target) => {
          target.innerHTML = `<img src="${thumbnailUrl}" alt="Video thumbnail">`
        })
      } else {
        this.clearOutputs()
      }
    } catch (error) {
      this.clearOutputs()
    }
  }

  clearOutputs() {
    this.outputTargets.forEach((target) => {
      target.innerHTML = ''
    })
  }

  detectVideoProvider(url) {
    if (url.match(/youtu/)) return 'youtube'
    if (url.match(/vimeo/)) return 'vimeo'
    if (url.match(/wistia/)) return 'wistia'
    if (url.match(/voomly/)) return 'voomly'
    return null
  }

  async fetchOembedThumbnail(url, provider) {
    const endpoints = {
      youtube: `https://www.youtube.com/oembed?url=${encodeURIComponent(
        url
      )}&format=json`,
      vimeo: `https://vimeo.com/api/oembed.json?url=${encodeURIComponent(url)}`,
      wistia: `https://fast.wistia.com/oembed?url=${encodeURIComponent(
        url
      )}&format=json`,
      voomly: null // Voomly might not have a public oEmbed API
    }

    const endpoint = endpoints[provider]
    if (!endpoint) return null

    const response = await fetch(endpoint)
    if (!response.ok) return null

    const data = await response.json()
    return data.thumbnail_url
  }

  clearInput() {
    this.inputTarget.value = ''
  }
}
