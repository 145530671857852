import { Controller } from '@hotwired/stimulus'

// <div data-controller="show-if" data-show-if-accepted-values-value="youtu|vimeo|wistia">
//   <%= render "shared/fields/text_field", method: :showcase_video_url, options: { data: { show_if_target: "input", action: "input->show-if#checkValue" } } %>
//   <%= render "shared/icon", icon: "logos/youtube-logo.svg", inline_svg_options: { data: { show_if_target: "output", name: "youtu" } } %>
//   <%= render "shared/icon", icon: "logos/vimeo-logo.svg", inline_svg_options: { data: { show_if_target: "output", name: "vimeo" } } %>
//   <%= render "shared/icon", icon: "logos/vidalytics-logo.svg", inline_svg_options: { data: { show_if_target: "output", name: "vidalytics" } } %>
//   <%= render "shared/icon", icon: "logos/wistia-logo.svg", inline_svg_options: { data: { show_if_target: "output", name: "wistia" } } %>
//   <%= render "shared/icon", icon: "logos/voomly-logo.svg", inline_svg_options: { data: { show_if_target: "output", name: "voomly" } } %>
// </div>

// if no inputValue, display all icons
// if inputValue, display only the icons that match the inputValue
export default class extends Controller {
  static targets = ['input', 'output']

  connect() {
    this.checkValue()
  }
  checkValue() {
    let inputValue = this.inputTarget.value
      ? this.inputTarget.value.toLowerCase()
      : this.inputTarget.textContent.toLowerCase()

    // Track if we find any matches
    let hasMatches = false

    this.outputTargets.forEach((target) => {
      const targetName = target.dataset.name?.toLowerCase()

      if (!inputValue) {
        // Show all targets when no input
        target.classList.remove('hidden')
        hasMatches = true
      } else if (targetName) {
        // Only check for matches if target has a non-empty name
        const nameMatches = targetName
          .split('|')
          .some((name) => inputValue.includes(name.trim()))
        if (nameMatches) {
          target.classList.remove('hidden')
          hasMatches = true
        } else {
          target.classList.add('hidden')
        }
      } else {
        // Hide targets with no name or empty name when there's input
        // target.classList.add('hidden')
      }
    })

    // Second pass: show empty-named targets if no matches found
    if (inputValue && !hasMatches) {
      this.outputTargets.forEach((target) => {
        const targetName = target.dataset.name.toLowerCase()
        if (!targetName) {
          target.classList.remove('hidden')
        }
      })
    }
  }
}
