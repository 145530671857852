import { Controller } from '@hotwired/stimulus'

/**
 * Drawer Controller
 *
 * A Stimulus.js controller that manages drawer/modal behavior.
 *
 * Targets:
 * - drawer: The main drawer element to show/hide
 * - content: The inner content area used for click-outside detection
 *
 * Classes:
 * - hidden: CSS class(es) to toggle drawer visibility
 *
 * Actions:
 * - drawer#open: Opens the drawer
 * - drawer#close: Closes the drawer (also triggered by ESC key)
 * - drawer#clickOutside: Closes drawer when clicking outside content area
 *
 * Data Attributes:
 * - data-default="open": Sets drawer's initial state (optional)
 */

export default class extends Controller {
  static targets = ['drawer', 'content']
  static classes = ['hidden']

  connect() {
    if (this.hasDrawerTarget) {
      if (
        this.drawerTarget.dataset.default === 'open' ||
        !this.drawerTarget.dataset.default
      ) {
        this.open()
      } else {
        this.close()
      }
    }
  }

  // Helper method to check drawer state
  isOpen() {
    if (!this.hasDrawerTarget || !this.drawerTarget) return false
    return !this.drawerTarget.classList.contains(this.hiddenClasses[0])
  }

  // Opens drawer and prevents event bubbling
  open(event) {
    if (event) {
      event.preventDefault()
      event.stopPropagation()
    }

    if (this.isOpen()) return // Don't open if already open
    this.drawerTarget.classList.remove(...this.hiddenClasses)
  }

  // Closes drawer and handles event cleanup
  close(event) {
    if (event) {
      event.preventDefault()
      event.stopPropagation()
    }

    if (!this.isOpen()) return // Don't close if already closed
    this.drawerTarget.classList.add(...this.hiddenClasses)
  }

  // Handles clicks outside the drawer content
  handleOverlayClick(event) {
    if (!this.contentTarget.contains(event.target)) {
      this.close()
    }
  }
}
