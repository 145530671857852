import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['toastContainer', 'toastHome']

  moveIntoDialog(event) {
    const openDialog = document.querySelector('dialog[open]')
    if (!openDialog) return

    if (!this.hasToastContainerTarget) return
    openDialog.appendChild(this.toastContainerTarget)
  }

  moveBackToHome(event) {
    if (!this.hasToastHomeTarget) return
    if (!this.hasToastContainerTarget) return

    this.toastHomeTarget.appendChild(this.toastContainerTarget)
  }
}
