import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = [
    'messageInput',
    'conversationList',
    'messageList',
    'submitButton',
    'messageInputField'
  ]

  connect() {
    // Restore the hidden state when the controller connects
    const conversationListHidden =
      localStorage.getItem('conversationListHidden') === 'true'
    if (conversationListHidden) {
      this.element.classList.add('conversation-list-hidden')
    }

    const collaborationDetailsHidden = localStorage.getItem(
      'collaborationDetailsHidden'
    )
    if (collaborationDetailsHidden === 'true') {
      this.element.classList.add('collaboration-details-hidden')
    }

    // A a mutation observer for when new messags are added to the messages list
    this.mutationObserver = new MutationObserver(() => {
      this.scrollToBottom()
    })

    this.mutationObserver.observe(this.messageListTarget, {
      childList: true,
      subtree: true
    })

    this.toggleSubmitButton()
  }

  disconnect() {
    // Clean up mutation observer
    if (this.mutationObserver) {
      this.mutationObserver.disconnect()
    }
  }

  toggleConversationsList() {
    this.element.classList.toggle('conversation-list-hidden')
    // Store the current state
    localStorage.setItem(
      'conversationListHidden',
      this.element.classList.contains('conversation-list-hidden')
    )
  }

  toggleCollaborationDetails() {
    this.element.classList.toggle('collaboration-details-hidden')
    // Store the current state
    localStorage.setItem(
      'collaborationDetailsHidden',
      this.element.classList.contains('collaboration-details-hidden')
    )
  }

  toggleSubmitButton() {
    const hasText = this.messageInputFieldTarget.value.trim() !== ''
    this.submitButtonTarget.classList.toggle('hidden', !hasText)
  }

  submit(event) {
    event.preventDefault()
    if (this.messageInputFieldTarget.value.trim() !== '') {
      this.messageInputFieldTarget.closest('form').requestSubmit()
    }
  }

  scrollToBottom() {
    const lastMessage = this.messageListTarget.querySelector(
      '.messages .chat-message:last-child'
    )
    if (lastMessage) {
      lastMessage.scrollIntoView({ behavior: 'smooth' })
    }
  }
}
