import { Controller } from '@hotwired/stimulus'
// only one input target can have a value at the same time. if one input changes, clear the other ones

// <div data-controller="single-input">
//   <input data-single-input-target="input" data-action="input->single-input#clearOtherInputs">
//   <input data-single-input-target="input" data-action="input->single-input#clearOtherInputs">
//   <input data-single-input-target="input" data-action="input->single-input#clearOtherInputs">
// </div>
export default class extends Controller {
  static targets = ['input']

  connect() {
    // Find all inputs with values
    const inputsWithValues = this.inputTargets.filter((input) => input.value)

    // If multiple inputs have values, keep only the first one
    if (inputsWithValues.length > 1) {
      // Clear all inputs except the first one with a value
      inputsWithValues.slice(1).forEach((input) => {
        input.value = ''
        input.dispatchEvent(new Event('change', { bubbles: true }))
      })
    }
  }

  // Called when any input changes
  clearOtherInputs({ target }) {
    // Skip if the input is being cleared (to avoid recursion)
    if (!target.value) return

    // Clear all other inputs except the current one
    this.inputTargets.forEach((input) => {
      if (input !== target) {
        input.value = ''
        input.dispatchEvent(new Event('change', { bubbles: true }))
      }
    })
  }
}
