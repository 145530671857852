import { Controller } from '@hotwired/stimulus'
import { prepareRecreatedDropEvent } from '../../utils/drop'

export default class extends Controller {
  static targets = [
    'selectedAssetField',
    'dialog',
    'filePreviewTemplate',
    'filePreviewImage',
    'filePreviewContainer',
    'previewProgressBar',
    'uploadInput'
  ]

  updateSelectedAsset(event) {
    const asset = event?.detail?.asset
    let assetId = asset?.id || event?.params?.id // action parameters as fallback

    if (assetId !== null && assetId !== undefined) {
      this.selectedAssetFieldTarget.value = assetId
      this.selectedAssetFieldTarget.dispatchEvent(
        new Event('change', { bubbles: true, cancelable: true })
      )
      this.selectedAssetFieldTarget.dispatchEvent(
        new Event('input', { bubbles: true, cancelable: true })
      )
    }
  }

  requestMedia() {
    if (!this.hasDialogTarget) {
      return
    }
    this.dialogTarget.dispatchEvent(
      new CustomEvent('dialog:request-open', {
        bubbles: true,
        cancelable: true
      })
    )
  }

  updateFilePreview(event) {
    if (!this.hasFilePreviewContainerTarget) {
      return
    }
    const { src, alt } = event?.detail
    if (!src || !alt) {
      return
    }

    this.replaceFilePreviewWithTemplate()

    if (!this.hasFilePreviewImageTarget) {
      return
    }

    this.filePreviewImageTarget.src = src
    this.filePreviewImageTarget.alt = alt

    if (!this.hasSelectedAssetFieldTarget) {
      return
    }
    this.selectedAssetFieldTarget.dataset.processing = true
  }

  replaceFilePreviewWithTemplate() {
    if (!this.hasFilePreviewContainerTarget) {
      return
    }
    if (!this.hasFilePreviewTemplateTarget) {
      return
    }

    this.filePreviewContainerTarget.innerHTML =
      this.filePreviewTemplateTarget.innerHTML
  }

  updatePreviewProgressBar(event) {
    if (!this.hasPreviewProgressBarTarget) {
      return
    }

    const { progress } = event?.detail
    this.previewProgressBarTarget.dispatchEvent(
      new CustomEvent('update-progress', { detail: { progress } })
    )
  }

  delegateDropToUploadInput(event) {
    if (!this.hasUploadInputTarget) {
      return
    }
    const recreatedDropEvent = prepareRecreatedDropEvent(event)
    if (!recreatedDropEvent) {
      return
    }
    this.uploadInputTarget.dispatchEvent(recreatedDropEvent)
  }
}
