import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = [
    'modalTemplate',
    'activeModal',
    'activeModalBody',
    'activeDropdown'
  ]
  static values = {
    breakpoint: { type: Number, default: 768 }
  }

  get mediaQuery() {
    return `(max-width: ${this.breakpointValue}px)`
  }

  get modalRequired() {
    return this.mediaQueryList?.matches
  }

  startResponsiveListener() {
    this.updateActiveDropdown = this.updateActiveDropdown.bind(this)
    this.mediaQueryList = window.matchMedia(this.mediaQuery)
    this.mediaQueryList.addEventListener('change', this.updateActiveDropdown)
  }

  removeResponsiveListener() {
    if (!this.mediaQueryList) {
      return
    }

    this.mediaQueryList.removeEventListener('change', this.updateActiveDropdown)
    this.mediaQueryList = undefined
  }

  connect() {
    this.startResponsiveListener()
  }

  disconnect() {
    this.removeResponsiveListener()
  }

  // Beyond the single mediaQuery listener, this controller manages a single modal
  // for displaying a single activeDropdown when this.modalRequired is true.

  // When a dropdown is shown, manageDropdown is called with the event, and
  // disregardDropdown is called when a dropdown is dismissed.

  manageDropdown(event) {
    let { dropdownElement } = event?.detail
    const contextElement = event?.currentTarget

    if (!dropdownElement) {
      const { dropdownSelector } = event?.params
      dropdownElement = contextElement.querySelector(dropdownSelector)
    }

    if (!dropdownElement) {
      return
    }

    this.activeDropdown = dropdownElement // see setter below

    let { title } = event?.params
    if (title) {
      this.activeDropdown.dataset.dropdownTitle = title
    } else {
      const labelElement = contextElement.querySelector('label')
      if (labelElement) {
        this.activeDropdown.dataset.dropdownTitle = labelElement.textContent
      }
    }

    this.updateActiveDropdown()
  }

  disregardDropdown(event) {
    if (this.modalShown) {
      event.preventDefault()
      this.dismissModal()
    }
    this.activeDropdown = undefined // see setter below
    this.updateActiveDropdown()
  }

  updateActiveDropdown() {
    if (!this.activeDropdown && this.hasActiveModalTarget) {
      this.dismissModal()
      return
    }

    if (this.modalRequired) {
      this.wrapDropdownInModalAndShow()
    } else if (this.hasActiveModalTarget) {
      this.dismissModal() // only unwrap after dismiss transition is complete
    }
  }

  wrapDropdownInModalAndShow() {
    if (!this.activeDropdown || !this.hasModalTemplateTarget) {
      return
    }

    const dropdownParent = this.activeDropdown.parentElement
    let modalContent =
      this.modalTemplateTarget.content.firstElementChild.cloneNode(true)

    const title = this.activeDropdown.dataset?.dropdownTitle || ''
    modalContent.innerHTML = modalContent.innerHTML.replace(
      '{{ title }}',
      title
    )

    dropdownParent.appendChild(modalContent)

    if (!this.hasActiveModalBodyTarget) {
      console.error('No active modal body target found')
      return
    }

    const modalBody = this.activeModalBodyTarget
    modalBody.appendChild(this.activeDropdown)
    this.showModal()
  }

  showModal() {
    if (!this.hasActiveModalTarget) {
      return
    }

    queueMicrotask(() => {
      this.activeModalTarget.dispatchEvent(
        new CustomEvent('dropdown-modal:open', { bubbles: true })
      )
    })
  }

  dismissModal() {
    if (!this.hasActiveModalTarget) {
      return
    }

    this.activeModalTarget.dispatchEvent(
      new CustomEvent('dropdown-modal:close', { bubbles: true })
    )
  }

  // relies on dialog:closed events, which we can't always rely on because of async nature of transitions
  unwrapDropdownFromModal(event) {
    if (!this.hasActiveModalBodyTarget || !this.hasActiveModalTarget) {
      return
    }

    const modal = this.activeModalTarget
    const dropdownParent = modal.parentElement
    const modalBody = this.activeModalBodyTarget
    const dropdown = modalBody.firstElementChild

    if (dropdown) {
      dropdownParent.appendChild(dropdown)
    }

    dropdownParent.removeChild(modal)
  }

  stopPropagationOfNestedDialogs(event) {
    if (!this.hasActiveModalBodyTarget) {
      return
    }

    if (this.activeModalBodyTarget.contains(event.target)) {
      event.stopPropagation()
    }
  }

  get modalShown() {
    return (
      this.hasActiveModalTarget &&
      this.activeModalTarget.querySelector('dialog[open]') !== null
    )
  }

  // Survive page restores by marking the activeDropdown in the DOM
  get activeDropdown() {
    if (!this.hasActiveDropdownTarget) {
      return undefined
    }
    return this.activeDropdownTarget
  }

  set activeDropdown(dropdownElement) {
    const targetAttributeName = `data-${this.identifier}-target`
    const targetName = 'activeDropdown'

    if (this.hasActiveDropdownTarget) {
      this.activeDropdownTarget.removeAttribute(targetAttributeName)
    }

    dropdownElement?.setAttribute(targetAttributeName, targetName)
  }
}
