import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['selectedLabel', 'selectedIcon']

  closeIfClickedOutside(event) {
    if (!this.element.contains(event.target)) {
      this.element.removeAttribute('open')
    }
  }

  updateSelection(event) {
    this.updateLabel(event)
    this.updateIcon(event)
  }

  updateLabel(event) {
    const value = event.target.value
    this.selectedLabelTarget.textContent = value || 'Not set'
  }

  updateIcon(event) {
    const value = event.target.value
    const iconKey = this.element.querySelector(`[data-icon-key="${value}"]`)
    this.selectedIconTarget.innerHTML = iconKey.innerHTML
  }
}
