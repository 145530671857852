import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="rich-text-validator"
export default class extends Controller {
  static targets = ["editor", "form"]
  static values = {
    debounce: { type: Number, default: 500 }
  }
  
  initialize() {
    // Bind the debounced submit to preserve context
    this.debouncedSubmit = this.debounce(this.submit.bind(this), this.debounceValue)
  }

  rhinoChange() {
    this.debouncedSubmit()
  }

  submit() {
    this.formTarget.requestSubmit()
  }

  // Utility method for debouncing
  debounce(fn, wait) {
    let timeout
    return function executedFunction(...args) {
      const later = () => {
        clearTimeout(timeout)
        fn(...args)
      }
      clearTimeout(timeout)
      timeout = setTimeout(later, wait)
    }
  }
}
