import "rhino-editor/exports/styles/trix.css"
import { TipTapEditor } from "rhino-editor/exports/elements/tip-tap-editor.js"


class RichTextEditor extends TipTapEditor {
  constructor() {
    super()

    this.addEventListener("rhino-before-initialize", (e) => {
      const editor = e.target
      editor.starterKitOptions = {
        ...editor.starterKitOptions,
        rhinoPlaceholder: {
          placeholder: this.dataset.placeholder
        }
      }
    })

    this.starterKitOptions = {
      ...this.starterKitOptions,
      horizontalRule: true,
      codeBlock: false,
      heading: {
        // Enable all heading levels
        levels: [1, 2, 3],
      },
      history: false,
    }
  }


  async startEditor() {
    await super.startEditor()

    this.addEventListener("click", (e) => {
      const button = e.target.closest("button[name='horizontal-rule']");
      if (button && (e.target === button || button.contains(e.target))) {
        if (e.defaultPrevented) return; // Skip if already handled
        e.preventDefault();
        e.stopPropagation();
        e.stopImmediatePropagation(); // This prevents other listeners on the same element

        this.editor.chain().focus().setHorizontalRule().run();
      }
    })
  }

  editorOptions() {
    return {
      parseOptions: {
        preserveWhitespace: 'full',
      },
    }
  }
}

RichTextEditor.define("rich-text-editor")