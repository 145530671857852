import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['focusReceiver']
  static values = {
    focusReceiverWithinSelector: String, // this.element.querySelector
    focusReceiverSelector: String // document.querySelector
  }

  focusOnReceiver() {
    if (!this.focusReceiver) return
    this.deferredFocusOn(this.focusReceiver)
  }

  focusOnReceiverOrItsAutofocus() {
    if (!this.focusReceiver) return
    const autofocusDescendant = this.focusReceiver.querySelector('[autofocus]')

    if (autofocusDescendant) {
      this.deferredFocusOn(autofocusDescendant)
    } else {
      this.deferredFocusOn(this.focusReceiver)
    }
  }

  // private

  ensureElementIsFocusable(element) {
    if (!element) return
    if (!element.hasAttribute('tabindex')) {
      element.setAttribute('tabindex', '-1')
    }
  }

  deferredFocusOn(element) {
    // if the original event is triggered by a user action, an element.focus() to another element might be cancelled
    queueMicrotask(() => {
      this.ensureElementIsFocusable(element)
      element.focus()
    })
  }

  get focusReceiver() {
    if (this.hasFocusReceiverWithinSelectorValue) {
      return this.element.querySelector(this.focusReceiverWithinSelectorValue)
    } else if (this.hasFocusReceiverSelectorValue) {
      return document.querySelector(this.focusReceiverSelectorValue)
    } else if (this.hasFocusReceiverTarget) {
      return this.focusReceiverTarget
    }

    return undefined
  }
}
