import { Controller } from "@hotwired/stimulus"

// This controller enables the submit button when any input of the form changes.
// This stimulus looks more difficult than it should be. The reason is that it supports forms fields that are dynamically added.
// Connects to data-controller="enable-form-on-change"
export default class extends Controller {
  static targets = ["button"]

  connect() {
    // Bind the handler to this instance
    this.boundChangeHandler = this.change.bind(this)

    this.initialized = false

    // Using mutation observer to detect changes in the form
    // This is handy if the inputs get dynamically added.
    this.observer = new MutationObserver(this.handleDomChanges.bind(this))
    this.observer.observe(this.element, {
      childList: true,
      subtree: true
    })

    // Set up initial listeners
    this.initializeIfReady()
  }

  disconnect() {
    this.observer.disconnect()
    this.removeAllListeners()
  }

  buttonTargetConnected(button) {
    button.disabled = true
    this.change() // Check initial state
  }

  handleDomChanges(mutations) {
    this.removeAllListeners()
    this.addAllListeners()
    this.initializeIfReady()
  }

  initializeIfReady() {
    if (this.initialized || !this.hasButtonTarget || this.inputs.length === 0) return

    this.initialized = true
    this.originalFormData = new FormData(this.element)
    this.addAllListeners()
    this.change()
  }

  addAllListeners() {
    this.inputs.forEach(input => {
      input.addEventListener('change', this.boundChangeHandler)
      input.addEventListener('input', this.boundChangeHandler)
    })
  }

  removeAllListeners() {
    this.inputs.forEach(input => {
      input.removeEventListener('change', this.boundChangeHandler)
      input.removeEventListener('input', this.boundChangeHandler)
    })
  }

  change() {
    if (!this.hasButtonTarget || !this.initialized) return

    const currentFormData = new FormData(this.element)
    this.buttonTarget.disabled = this.formDataEquals(this.originalFormData, currentFormData)
  }

  formDataEquals(formData1, formData2) {
    const entries1 = Array.from(formData1.entries())
    const entries2 = Array.from(formData2.entries())

    if (entries1.length !== entries2.length) return false

    const map2 = new Map(entries2)

    return entries1.every(([key, value]) => map2.get(key) === value)
  }

  get inputs() {
    return this.element.querySelectorAll('input, select, textarea')
  }
}
