import { Controller } from '@hotwired/stimulus'
import { computePosition, shift, autoUpdate } from '@floating-ui/dom'

export default class extends Controller {
  static targets = [
    'superSelect',
    'selectElement',
    'button',
    'selectionText',
    'label',
    'wrapper'
  ]
  static values = {
    hideLabelOnSelection: {
      type: Boolean,
      default: false
    },
    defaultValue: {
      type: String,
      default: null
    }
  }

  connect() {
    if (!this.defaultValueValue) {
      this.updateSelectedAttribute()
    }
  }

  disconnect() {
    this.removePositionerFor()
  }

  toggleDropdown() {
    if (!this.hasSuperSelectTarget) return

    const event = new CustomEvent(`${this.identifier}:toggleDropdown`)
    this.superSelectTarget.dispatchEvent(event)
  }

  updateSelectionText(event) {
    if (!this.hasSelectionTextTarget) return

    const numSelectedOptions = this.selectedOptions.length
    if (numSelectedOptions === 0) {
      this.selectionTextTarget.textContent = ''
    } else if (this.isMultiple) {
      this.selectionTextTarget.textContent = `${numSelectedOptions}`
    } else {
      this.selectionTextTarget.textContent =
        event?.detail?.event?.params?.data?.text
      if (this.hideLabelOnSelectionValue && this.hasLabelTarget) {
        this.labelTarget.classList.add('hidden')
      }
    }

    this.updateSelectedAttribute()
  }

  // this is necessary because the super select will close the dropdown on window@mousedown
  preventSuperSelectFromClosing(event) {
    if (!this.hasButtonTarget) return
    if (event.currentTarget !== this.buttonTarget) return
    if (event.type === 'mousedown') {
      event.stopPropagation()
    }
  }

  startPositionerFor() {
    const dropdown = this.dropdown

    if (!dropdown) return
    dropdown.removePositioner = autoUpdate(this.wrapperTarget, dropdown, () => {
      computePosition(this.wrapperTarget, dropdown, {
        placement: 'bottom-start',
        middleware: [
          shift({
            padding: 8
          })
        ]
      }).then(({ x }) => {
        Object.assign(dropdown.style, {
          left: `${x}px`
        })
      })
    })
  }

  removePositionerFor() {
    const dropdown = this.dropdown
    if (dropdown && typeof dropdown.removePositioner === 'function') {
      dropdown.removePositioner()
    }
  }

  updateSelectedAttribute(selected = this.isSelected) {
    if (!this.hasButtonTarget) return

    this.buttonTarget.dataset.selected = selected
  }

  get isSelected() {
    if (!this.hasSelectionTextTarget) return false
    if (!this.hasSelectElementTarget) return false
    return this.selectedOptions.length > 0
  }

  get selectedOptions() {
    if (!this.hasSelectElementTarget) return []
    return Array.from(this.selectElementTarget.selectedOptions).filter(
      (option) => option.value.trim() !== ''
    )
  }

  get isMultiple() {
    if (!this.hasSelectElementTarget) return false
    return this.selectElementTarget.multiple
  }

  get dropdown() {
    return this.wrapper.querySelector('.select2-dropdown')
  }

  get wrapper() {
    if (this.hasWrapperTarget) return this.wrapperTarget
    return this.element
  }
}
