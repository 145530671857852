import { Controller } from '@hotwired/stimulus'
import { prepareRecreatedDropEvent } from '../../utils/drop'

export default class extends Controller {
  static targets = [
    'includedAsset',
    'assetIdField',
    'sortOrderField',
    'uploadableAssetTemplate',
    'selectedAssetTemplate',
    'dropzone',
    'itemList',
    'removedList',
    'assetDialog',
    'assetDialogInput',
    'visualPlaceholder'
  ]

  static values = {
    updateLayoutEventName: String,
    countNewItems: {
      type: Number,
      default: 0
    },
    idPlaceholder: {
      type: String,
      default: '%id%'
    }
  }

  connect() {
    this.updateLayout()
  }

  updateLayout(event) {
    if (this.hasUpdateLayoutEventNameValue) {
      this.dropzoneTarget.dispatchEvent(
        new CustomEvent(`${this.updateLayoutEventNameValue}`, {
          bubbles: true,
          cancelable: true,
          detail: {
            showEmptyState: !this.hasAssets,
            count: this.assetsCount
          }
        })
      )
    }

    this.updateSortOrder()
    this.updateVisualPlaceholders()
  }

  requestMedia() {
    this.showAssetDialog()
  }

  addAssetsFromFiles(event) {
    this.hideAssetDialog()

    if (!this.hasUploadableAssetTemplateTarget) {
      return
    }
    const files = event?.detail?.files
    if (!files) {
      return
    }

    files.forEach((file) => {
      this.addAssetFromFile(file)
    })
  }

  addAssetFromFile(file) {
    if (!this.hasUploadableAssetTemplateTarget) {
      return
    }

    let newIncludedAsset = this.createUploadableAssetFromTemplateWithFile(file)
    this.itemListTarget.appendChild(newIncludedAsset)

    this.updateLayout()
    this.countNewItemsValue += 1
  }

  addSelectedAsset(event) {
    if (!this.hasSelectedAssetTemplateTarget) {
      return
    }
    const image_container = event?.currentTarget
    const image = image_container.querySelector('img')
    const assetId = event?.params?.id
    const imageUrl = image.src

    let newIncludedAsset = this.createSelectedAssetFromTemplate(
      assetId,
      imageUrl
    )
    this.itemListTarget.appendChild(newIncludedAsset)

    this.updateLayout()
    this.countNewItemsValue += 1

    this.element.dispatchEvent(
      new Event('input', { bubbles: true, cancelable: true })
    )

    this.hideAssetDialog()
  }

  createUploadableAssetFromTemplateWithFile(file) {
    let newUploadableAsset =
      this.uploadableAssetTemplateTarget.content.firstElementChild.cloneNode(
        true
      )
    newUploadableAsset.innerHTML = newUploadableAsset.innerHTML.replaceAll(
      this.idPlaceholderValue,
      this.countNewItemsValue + 1000
    )
    newUploadableAsset.file = file // attach as a property on the element, will be picked up by asset_controller

    return newUploadableAsset
  }

  createSelectedAssetFromTemplate(assetId, imageUrl) {
    let newSelectedAsset =
      this.selectedAssetTemplateTarget.content.firstElementChild.cloneNode(true)
    newSelectedAsset.innerHTML = newSelectedAsset.innerHTML.replaceAll(
      this.idPlaceholderValue,
      this.countNewItemsValue + 1000
    )

    const assetIdField = newSelectedAsset.querySelector('[data-asset-id-field]')
    if (assetIdField) {
      assetIdField.value = assetId
    }

    const filePreviewImage = newSelectedAsset.querySelector(
      '[data-image-preview]'
    )
    if (filePreviewImage) {
      filePreviewImage.src = imageUrl
    }

    return newSelectedAsset
  }

  showAssetDialog() {
    if (!this.hasAssetDialogTarget) {
      return
    }
    this.assetDialogTarget.dispatchEvent(
      new CustomEvent('dialog:request-open', {
        bubbles: true,
        cancelable: true
      })
    )
  }

  hideAssetDialog() {
    if (!this.hasAssetDialogTarget) {
      return
    }
    this.assetDialogTarget.close()
  }

  removeIncludedAsset(event) {
    const destroyField = event?.currentTarget
    if (destroyField?.value == '') {
      return
    }

    let includedAssetToRemove = this.includedAssetTargets.find((target) => {
      return target.contains(destroyField)
    })

    if (!includedAssetToRemove) {
      return
    }

    if (this.hasRemovedListTarget) {
      this.removedListTarget.appendChild(includedAssetToRemove)
    } else {
      includedAssetToRemove.remove()
    }

    this.updateLayout()
  }

  // we let asset_input handle the file filtering, so files can be dropped here, or in the dialog chrome,
  // or in the asset dialog's own input, and they'll be handled the same way
  dropIntoAssetDialogInput(event) {
    if (!this.hasAssetDialogInputTarget) {
      return
    }

    const recreatedDropEvent = prepareRecreatedDropEvent(event)
    if (!recreatedDropEvent) {
      return
    }

    this.assetDialogInputTarget.dispatchEvent(recreatedDropEvent)
  }

  updateSortOrder() {
    this.sortOrderFieldTargets.forEach((sortOrderField, index) => {
      sortOrderField.value = index
    })
  }

  updateVisualPlaceholders() {
    this.visualPlaceholderTargets.forEach((visualPlaceholder, index) => {
      if (index < this.assetsCount) {
        visualPlaceholder.classList.add('hidden')
      } else {
        visualPlaceholder.classList.remove('hidden')
      }
    })
  }

  get hasAssets() {
    return this.assetsCount > 0
  }

  get assetsCount() {
    return this.includedAssets.length
  }

  get includedAssets() {
    return this.includedAssetTargets.filter((assetIdField) => {
      return this.itemListTarget.contains(assetIdField)
    })
  }
}
