import { Extension } from '@tiptap/core'
import { Plugin } from 'prosemirror-state'
import emojiRegex from 'emoji-regex'

/**
 * Get the emoji regex pattern
 * @private
 * @returns {RegExp} The emoji regular expression
 */
const getEmojiRegex = () => emojiRegex()

/**
 * Event name for emoji detection
 * @type {string}
 */
const EVENT_NAME = 'emoji-detected'

/**
 * TipTap extension that prevents emoji input and cleans pasted text
 */
export const PreventEmoji = Extension.create({
  name: 'preventEmoji',

  addProseMirrorPlugins() {
    return [
      new Plugin({
        // 1. Direct input prevention (when typing)
        filterTransaction: (tr) => {
          if (!tr.docChanged) return true

          let hasEmoji = false
          tr.steps.forEach((step) => {
            if (step.slice) {
              const text =
                step.slice.content?.textBetween?.(0, step.slice.content.size) ||
                ''
              if (getEmojiRegex().test(text)) {
                hasEmoji = true
                // Notify controller when emoji is detected
                const editorElement = this.editor.options.element
                editorElement.dispatchEvent(
                  new CustomEvent(EVENT_NAME, { bubbles: true })
                )
              }
            }
          })
          return !hasEmoji // Block the input if emoji found
        },

        props: {
          // 2. Paste handling (when pasting text)
          handlePaste: (view, event) => {
            const text = event.clipboardData?.getData('text')
            if (!text || !getEmojiRegex().test(text)) return false

            const detected = text.replace(getEmojiRegex(), '') // Remove emojis
            if (detected) {
              view.dispatch(view.state.tr.insertText(detected))
              // Notify controller when text is cleaned
              const editorElement = this.editor.options.element
              editorElement.dispatchEvent(
                new CustomEvent(EVENT_NAME, { bubbles: true })
              )
            }
            return true
          }
        }
      })
    ]
  }
})
