import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['submitButton']

  static values = {
    interval: { type: Number, default: 10000 },
    startOnConnect: { type: Boolean, default: true },
    submitsWith: { type: String, default: 'Loading...' }
  }

  connect() {
    if (this.startOnConnectValue) {
      this.startPolling()
    }
  }

  disconnect() {
    this.stopPolling()
  }

  startPolling() {
    this.timer = setInterval(() => {
      this.submitForm()
    }, this.intervalValue)
  }

  clickSubmit(event) {
    event.preventDefault()
    this.startPolling()
    this.submitForm()
  }

  stopPolling() {
    if (this.timer) {
      clearInterval(this.timer)
    }
  }

  submitForm() {
    const form = this.element

    if (this.hasSubmitButtonTarget) {
      this.originalSubmitButtonText = this.submitButtonText

      if (this.hasSubmitsWithValue) {
        this.submitButtonText = this.submitsWithValue
      }
      this.submitButtonTarget.disabled = true
    }

    form.requestSubmit()
  }

  set submitButtonText(text) {
    if (this.submitButtonTarget.tagName === 'INPUT') {
      this.submitButtonTarget.value = text
    } else {
      this.submitButtonTarget.innerText = text
    }
  }

  get submitButtonText() {
    return this.submitButtonTarget.tagName === 'INPUT'
      ? this.submitButtonTarget.value
      : this.submitButtonTarget.innerText
  }
}
