import { Controller } from '@hotwired/stimulus'
import { PreventEmoji } from '../extensions/rhino_prevent_emoji_extension'

/**
 * Handles emoji prevention feedback in rich text editors
 * @stimulus rhino-emoji-prevention
 */
export default class extends Controller {
  static targets = ['error']
  static values = {
    message: { type: String, default: 'Emojis are not allowed in this field' },
    debug: { type: Boolean, default: false }
  }
  static classes = ['visible']

  /**
   * Enables emoji prevention for a specific editor
   * Action: rhino-before-initialize->rhino-emoji-prevention#enableEmojiPrevention
   */
  enableEmojiPrevention(event) {
    try {
      const editor = event.target
      if (!editor.extensions?.includes(PreventEmoji)) {
        editor.extensions = [PreventEmoji, ...(editor.extensions || [])]
      }
    } catch (error) {
      console.error('Error initializing PreventEmoji:', error)
    }
  }

  /**
   * Handles the emoji-detected event
   * Action: emoji-detected->rhino-emoji-prevention#emojiDetected
   */
  emojiDetected() {
    this.debouncedShowFeedback()
  }

  /**
   * Shows feedback message with debounce
   * @private
   */
  debouncedShowFeedback = this.debounce(() => {
    this.showFeedback()
  }, 100)

  /**
   * Shows feedback message
   * @private
   */
  showFeedback() {
    if (!this.hasErrorTarget) return

    this.clearFeedbackTimeout()
    this.errorTarget.textContent = this.messageValue
    this.errorTarget.classList.add(this.visibleClass)

    this._feedbackTimeout = setTimeout(() => this.hideFeedback(), 3000)
  }

  /**
   * Hides feedback message
   * @private
   */
  hideFeedback() {
    if (!this.hasErrorTarget) return
    this.errorTarget.textContent = ''
    this.errorTarget.classList.remove(this.visibleClass)
    this.clearFeedbackTimeout()
  }

  /**
   * Clears the feedback timeout
   * @private
   */
  clearFeedbackTimeout() {
    if (this._feedbackTimeout) {
      clearTimeout(this._feedbackTimeout)
      this._feedbackTimeout = null
    }
  }

  /**
   * Debounce utility function
   * @private
   */
  debounce(func, wait) {
    let timeout
    return (...args) => {
      clearTimeout(timeout)
      timeout = setTimeout(() => func.apply(this, args), wait)
    }
  }
}
