import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['menuItemHeader', 'menuItemGroup', 'menuItemLink']

  connect() {
    document.addEventListener('click', this.handleClickOutside.bind(this))
  }

  disconnect() {
    document.removeEventListener('click', this.handleClickOutside.bind(this))
  }

  toggle(event) {
    event.stopPropagation()
    this.menuItemGroupTarget.classList.toggle('invisible')
  }

  showSubmenu() {
    this.menuItemGroupTarget.classList.remove('invisible')
  }

  hideSubmenu(event) {
    let hideMenu = false

    if (event.key == 'Tab' && !event.shiftKey) {
      let lastIndex = this.menuItemLinkTargets.length - 1
      hideMenu = event.target == this.menuItemLinkTargets[lastIndex]
    } else if (event.key == 'Tab' && event.shiftKey) {
      hideMenu = event.target == this.menuItemHeaderTarget
    }

    if (hideMenu) {
      this.menuItemGroupTarget.classList.add('invisible')
    }
  }

  handleClickOutside(event) {
    if (!this.element.contains(event.target)) {
      this.menuItemGroupTarget.classList.add('invisible')
    }
  }
}
