import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["loader"]

  connect() {
  }

  submitStart() {
    this.loaderTarget.disabled = true
  }

  submitEnd() {
    this.loaderTarget.disabled = false
  }

}