import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['field']

  toggle() {
    this.field.disabled = !this.field.disabled
    if (this.field.disabled) {
      // TODO - pascal we want to clear the input when toggling off
      const input = this.field.querySelector('input')
      if (input) {
        input.value = ''
      }
    }
  }

  toggleAndFocusOnFirstInput() {
    this.toggle()
    window.setTimeout(() => {
      this.focusOnFirstInput()
    }, 100)
  }

  focusOnFirstInput() {
    const firstInput = this.element.querySelector('input')
    if (firstInput) {
      firstInput.focus()
    } else {
      this.field.focus()
    }
  }

  get field() {
    return this.hasFieldTarget ? this.fieldTarget : this.element
  }
}
