// imported from atelier
import { Controller } from '@hotwired/stimulus'
import '@simonwep/pickr/dist/themes/monolith.min.css'

import Pickr from '@simonwep/pickr'

export default class extends Controller {
  static targets = [
    'colorInput',
    'mobileModalColorInput',
    'colorPreview',
    'pickerContainer'
  ]
  static values = {
    lastValidColor: { type: String, default: null },
    autosubmit: String
  }

  connect() {
    this.initPluginInstance()

    if (this.isValidColor(this.selectedColor)) {
      this.lastValidColorValue = this.selectedColor
    }
  }

  disconnect() {
    this.teardownPluginInstance()
  }

  dispatchChangeEvent() {
    this.colorInputTarget.dispatchEvent(new Event('change'))
  }

  initPluginInstance() {
    if (!this.hasColorPreviewTarget || !this.hasPickerContainerTarget) return

    this.pickr = Pickr.create({
      el: this.colorPreviewTarget,
      container: this.pickerContainerTarget,
      theme: 'monolith',
      useAsButton: true,
      default: this.selectedColor || '#1E90FF',
      defaultRepresentation: 'hex',
      components: {
        // Main components
        hue: true,
        opacity: false,
        inline: true,
        showAlways: true,

        // Input / output Options
        interaction: {
          input: false,
          save: false
        }
      }
    })

    this.pickr.on('change', (color, source, _instance) => {
      const colorHex = color.toHEXA().toString().toLowerCase()
      this.selectedColor = colorHex
      this.trySubmit({ target: this.element })
    })
  }

  isValidColor(color) {
    if (color === '') return true
    if (color === undefined) return true
    if (color === null) return true
    return /^#([0-9a-fA-F]{6})$/.test(color)
  }

  updatePickerColorIfValid(event) {
    const color = event.target.value
    if (!this.isValidColor(color)) return

    this.lastValidColorValue = color
    this.previewedColor = color
    this.updateOtherColorInput(event.target, color)
  }

  updateOtherColorInput(changedField, color) {
    if (!this.hasMobileModalColorInputTarget || !this.hasColorInputTarget)
      return

    if (changedField === this.mobileModalColorInputTarget) {
      this.colorInputTarget.value = color
    } else if (changedField === this.colorInputTarget) {
      this.mobileModalColorInputTarget.value = color
    }
  }

  revertColorIfInvalid(event) {
    const color = event.target.value
    if (this.isValidColor(color)) return

    this.selectedColor = this.lastValidColorValue
  }

  teardownPluginInstance() {
    this.pickr.destroy()
  }

  get selectedColor() {
    return this.colorInputTarget.value
  }

  set selectedColor(newValue) {
    this.colorInputTarget.value = newValue
    if (this.hasMobileModalColorInputTarget) {
      this.mobileModalColorInputTarget.value = newValue
    }
    this.previewedColor = newValue
    this.lastValidColorValue = newValue
  }

  get previewedColor() {
    return this.pickr.getColor().toHEXA().toString().toLowerCase()
  }

  set previewedColor(newValue) {
    if (newValue) {
      this.pickr.setColor(newValue)
    }
    if (this.hasColorPreviewTarget) {
      this.colorPreviewTargets.forEach((previewEl) => {
        previewEl.style.backgroundColor = newValue || 'transparent'
      })
    }
  }

  trySubmit(event) {
    if (this.autosubmitValue === 'true') {
      const formElement = event.target.closest('form')
      if (formElement) {
        formElement.requestSubmit()
      }
    }
  }
}
